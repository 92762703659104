import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Row,
  Col,
  Button,
  Table,
  UncontrolledTooltip
} from "reactstrap";
// Components
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
// Helpers
import { getCredentials } from 'helpers/auth';
// Requests
import { getCorporationSettingsRequest, deleteCorporationSettingsRequest } from 'api/corporationSetting'

class Settings extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            corporationSettings: null,
            deleteId: ''
        }
    }

    componentDidMount() {
        const auth = getCredentials();
        if (auth.corporationId) this.getCorporationSettings()
    }

    getCorporationSettings = async () => {
        try {
            const auth = getCredentials();
    
            const { corporationSettings } = await getCorporationSettingsRequest(0, 0, '', auth.corporationId, "sellerTier")
    
            this.setState({
                corporationSettings
            })        
        } catch (error) {
            console.log('error', error)
        }
    }


    goTo = path => this.props.history.push(path)

    openConfirmation = (deleteId) => () => {
		this.setState({
			deleteId
		});
		this.deleteConfirmationModal.toggle();
    }

    deleteTier = async () => {
		const { deleteId } = this.state;
        await deleteCorporationSettingsRequest(deleteId);
        this.getCorporationSettings()
	}

    render() {

        const {
            corporationSettings
        } = this.state;

        const auth = getCredentials();
        
        const lastTierNumber = corporationSettings && corporationSettings.length ? corporationSettings[corporationSettings.length - 1].tierNumber : "1";
        const nextTierNumber = (Number(lastTierNumber) + 1).toString();


        return (
        <>
            <div className="content">
            <Row>
                <Col xs="12">
                    <Card className="card-chart">
                        <CardHeader className="mb-5">
                            <CardTitle tag="h3">
                                Settings
                            </CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Card className='detail-card'>
                                <CardHeader>
                                    <h3>Client Tiers</h3>
                                    <Button className="pull-right mb-3" color="primary" onClick={() => this.goTo(`/settings/create-tier?corporationId=${auth.corporationId}&tierNumber=${nextTierNumber}`)}>Create Tier</Button>
                                </CardHeader>
                                <CardBody>
                                    {corporationSettings 
                                    ? <Table responsive>
                                        <thead className="text-primary">
                                            <tr>
                                                <th>Name</th>
                                                <th>Details</th>
                                                <th>Description</th>
                                                <th className="text-right">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr key={1}>
                                                <td>Tier 1</td>
                                                <td>0% discount in all products</td>
                                                <td>Applied by default to all clients</td>
                                                <td className="text-right">
                                                    N/A
                                                </td>
                                            </tr>
                                            {corporationSettings.length
                                            ? corporationSettings.map(({
                                                    _id,
                                                    tierNumber,
                                                    description,
                                                    discountPercent
                                                }) => (
                                                    <tr key={tierNumber}>
                                                        <td>Tier {tierNumber}</td>
                                                        <td>{discountPercent * 100}% discount in all products</td>
                                                        <td>{description}</td>
                                                        <td className="text-right">
                                                            <Button
                                                                className="btn-link btn-icon"
                                                                color="success"
                                                                id={`tooltip-view-${_id}`}
                                                                size="sm"
                                                                onClick={() => this.goTo(`/settings/view-tier?id=${_id}`)}
                                                            >
                                                                <i className="tim-icons icon-zoom-split" />
                                                            </Button>
                                                            <UncontrolledTooltip
                                                                delay={0}
                                                                target={`tooltip-view-${_id}`}
                                                            >
                                                                View
                                                            </UncontrolledTooltip>
                                                            <Button
                                                                className="btn-link btn-icon"
                                                                color="info"
                                                                id={`tooltip-edit-${_id}`}
                                                                size="sm"
                                                                onClick={() => this.goTo(`/settings/edit-tier?id=${_id}`)}
                                                            >
                                                                <i className="tim-icons icon-pencil" />
                                                            </Button>
                                                            <UncontrolledTooltip
                                                                delay={0}
                                                                target={`tooltip-edit-${_id}`}
                                                            >
                                                                Edit
                                                            </UncontrolledTooltip>
                                                            {tierNumber === lastTierNumber &&
                                                                <>
                                                                    <Button
                                                                        className="btn-link btn-icon"
                                                                        color="danger"
                                                                        id={`tooltip-delete-${_id}`}
                                                                        size="sm"
                                                                        onClick={this.openConfirmation(_id)}
                                                                    >
                                                                        <i className="tim-icons icon-simple-remove" />
                                                                    </Button>
                                                                    <UncontrolledTooltip
                                                                        delay={0}
                                                                        target={`tooltip-delete-${_id}`}
                                                                    >
                                                                        Delete
                                                                    </UncontrolledTooltip>
                                                                </>
                                                            }
                                                            
                                                        </td>
                                                    </tr>
                                                ))
                                            :   null}
                                        </tbody>
                                    </Table>
                                    : 
                                    <p>Loading...</p>
                                    }
                                </CardBody>
                            </Card>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <ConfirmationModal
                ref={el => this.deleteConfirmationModal = el}
                title="Delete Tier?"
                buttons={[
                    { title: 'Cancel', className: 'btn-primary-override btn btn-primary' },
                    { title: 'Delete', className: 'btn-danger-override btn btn-danger', onClick: this.deleteTier }
                ]}
            >
                Are you sure you want to delete this tier?
            </ConfirmationModal>
            </div>
        </>
        );
    }
}

export default Settings;
