import Login from "pages/Login.js";
import Register from "pages/Register.js";
import Transactions from "pages/Transactions";
import TransactionDetails from "pages/TransactionDetails";
import ComingSoon from "pages/ComingSoon.js";
import Stores from "pages/Stores.js";
import StoreCreate from "pages/StoreCreate.js";
import StoreDetails from "pages/StoreDetails.js";
import StoreEdit from "pages/StoreEdit.js";
import Settings from "pages/Settings.js";
import TierCreate from "pages/TierCreate.js";
import TierDetails from "pages/TierDetails.js";
import Clients from "pages/Clients.js";
import CustomPrices from "pages/CustomPrices.js";
import CustomPricesCreate from "pages/CustomPricesCreate.js";
import CustomPriceDetails from "pages/CustomPriceDetails.js";
import Transfers from "pages/Transfers.js";
import TransfersCreate from "pages/TransfersCreate.js";
import TransferDetails from "pages/TransferDetails.js";
import Products from "pages/Products";
import ProductDetails from "pages/ProductDetails";
import CustomPriceEdit from "pages/CustomPriceEdit";
import SendEmail from "pages/SendEmail";
import ChangePassword from "pages/ChangePassword";

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "",
    icon: "tim-icons icon-chart-pie-36",
    component: ComingSoon,
    layout: "/dashboard",
    permission: 'ViewDashboard'
  },
  {
    path: "/stores",
    name: "Stores",
    rtlName: "",
    icon: "tim-icons icon-square-pin",
    component: Stores,
    layout: "/dashboard",
    permission: 'ViewStores'
  },
  {
    path: "/transactions",
    name: "Financial",
    rtlName: "",
    icon: "tim-icons icon-money-coins",
    component: Transactions,
    layout: "/dashboard",
    permission: 'ViewFinancial'
  },
  {
    path: "/clients",
    name: "Clients",
    rtlName: "",
    icon: "tim-icons icon-single-02",
    component: Clients,
    layout: "/dashboard",
    permission: 'ViewClients'
  },
  {
    path: "/products",
    name: "Products",
    rtlName: "",
    icon: "tim-icons icon-basket-simple",
    component: Products,
    layout: "/dashboard",
    permission: 'ViewProducts'
  },
  {
    path: "/deals",
    name: "Deals",
    rtlName: "",
    icon: "tim-icons icon-tag",
    component: ComingSoon,
    layout: "/dashboard",
    permission: 'ViewDeals'
  },
  {
    path: "/client-tiers",
    name: "Client Tiers",
    rtlName: "",
    icon: "tim-icons icon-settings",
    component: Settings,
    layout: "/dashboard",
    permission: 'ViewClientTiers'
  },
  {
    path: "/custom-prices",
    name: "Custom Prices",
    rtlName: "",
    icon: "tim-icons icon-settings",
    component: CustomPrices,
    layout: "/dashboard",
    permission: 'ViewCustomPrices'
  },
  {
    path: "/transfers",
    name: "Transfers",
    rtlName: "",
    icon: "tim-icons icon-refresh-02",
    component: Transfers,
    layout: "/dashboard",
    permission: 'ViewTransfers'
  },
  /* Invisible routes (not showing in Sidebar) below this line */
  {
    path: "/login",
    name: "Login",
    rtlName: "",
    component: Login,
    layout: "/auth",
    invisible: true
  },
  {
    path: "/register",
    name: "Register",
    rtlName: "",
    component: Register,
    layout: "/auth",
    invisible: true
  },
  {
    path: "/send-email",
    name: "Send Email",
    rtlName: "",
    component: SendEmail,
    layout: "/auth",
    invisible: true
  },
  {
    path: "/change-password",
    name: "Change Password",
    rtlName: "",
    component: ChangePassword,
    layout: "/auth",
    invisible: true
  },
  {
    path: "/transaction",
    name: "Transaction Details",
    rtlName: "",
    component: TransactionDetails,
    layout: "/dashboard",
    invisible: true,
    permission: 'ViewFinancial'
  },
  {
    path: "/store",
    name: "Store Details",
    rtlName: "",
    component: StoreDetails,
    layout: "/dashboard",
    invisible: true,
    permission: 'ViewStores'
  },
  {
    path: "/store/create",
    name: "Create Store",
    rtlName: "",
    component: StoreCreate,
    layout: "/dashboard",
    invisible: true,
    permission: 'ViewStores'
  },
  {
    path: "/store/edit",
    name: "Edit Store",
    rtlName: "",
    component: StoreEdit,
    layout: "/dashboard",
    invisible: true,
    permission: 'ViewStores'
  },
  {
    path: "/store/link",
    name: "Link Store",
    rtlName: "",
    component: ComingSoon,
    layout: "/dashboard",
    invisible: true,
    permission: 'ViewStores'
  },
  {
    path: "/client-tiers/view-tier",
    name: "Tier Details",
    rtlName: "",
    component: TierDetails,
    layout: "/dashboard",
    invisible: true,
    permission: 'ViewClientTiers'
  },
  {
    path: "/client-tiers/create-tier",
    name: "Create Tier",
    rtlName: "",
    component: TierCreate,
    layout: "/dashboard",
    invisible: true,
    permission: 'ViewClientTiers'
  },
  {
    path: "/custom-prices/create",
    name: "Create Custom Prices",
    rtlName: "",
    component: CustomPricesCreate,
    layout: "/dashboard",
    invisible: true,
    permission: 'ViewCustomPrices'
  },
  {
    path: "/custom-price",
    name: "Custom Prices Details",
    rtlName: "",
    component: CustomPriceDetails,
    layout: "/dashboard",
    invisible: true,
    permission: 'ViewCustomPrices'
  },
  {
    path: "/custom-price/edit",
    name: "Edit Custom Price",
    rtlName: "",
    component: CustomPriceEdit,
    layout: "/dashboard",
    invisible: true,
    permission: 'ViewCustomPrices'
  },
  {
    path: "/transfer",
    name: "Transfer",
    rtlName: "",
    component: TransferDetails,
    layout: "/dashboard",
    invisible: true,
    permission: 'ViewTransfers'
  },
  {
    path: "/transfers/create",
    name: "Create Transfer",
    rtlName: "",
    component: TransfersCreate,
    layout: "/dashboard",
    invisible: true,
    permission: 'ViewTransfers'
  },
  {
    path: "/product",
    name: "Product Details",
    rtlName: "",
    component: ProductDetails,
    layout: "/dashboard",
    invisible: true,
    permission: 'ViewProducts'
  },
];

export default routes;
