import React, { PureComponent } from 'react';
import { Container, Row, Col, FormGroup, Label, Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert, Input } from 'reactstrap';
import Datetime from "react-datetime";
import CurrencyInput from 'components/CurrencyInput/CurrencyInput';

export default class UpdateTierModal extends PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			modal: false,
            targetTier: '',
			error: ''
		}
	}

	toggle = () => this.setState(prevState => ({ modal: !prevState.modal, error: '' }));


	componentDidUpdate(prevProps) {
		if(this.props.error !== prevProps.error) {
			this.setState({
				error: this.props.error
			});
		}
	}

	onSave = () => {
		const { 
            targetTier
		} = this.state;

		const { 
			onSave
		} = this.props;


		onSave(targetTier)
	}

	render() {

		const { 
            targetTier,
			error
		} = this.state;

		const {
			loading
		} = this.props;


		return (
			<Modal isOpen={this.state.modal} toggle={this.toggle}>
				<ModalHeader>Update Tier</ModalHeader>
				<ModalBody>
					<Container>
						<Row>
							<Col>	
								<FormGroup>
									<Label>Target Tier</Label>
									<Input placeholder="" value={targetTier} type="text" onChange={(event) => this.setState({ targetTier: event.target.value })}></Input>
								</FormGroup>
							</Col>
						</Row>
					</Container>
					{error && 
                        <Alert color="danger">
                            {error}
                        </Alert>
                    }
				</ModalBody>
				<ModalFooter className="justify-content-end">
						<Button 
							color="primary" 
							className="btn-success-override mr-2"
							style={{}} 
							disabled={!targetTier || loading}
							onClick={this.onSave}
						>
							{loading ? "Saving" : "Save"}
						</Button>
						<Button color="secondary" className="btn-danger-override" onClick={this.toggle}>Cancel</Button>
				</ModalFooter>
			</Modal>
		)
	}
}