import React from "react";
import { 
    Button, 
    Card, 
    CardBody, 
    CardFooter, 
    CardHeader, 
    CardTitle, 
    Col, 
    Row, 
    Table, 
    UncontrolledTooltip 
} from "reactstrap";
import { Link } from "react-router-dom";
import PaginationComponent from "components/Custom/Pagination";
import { calcStartEndPage } from "helpers/pagination";
import { formatRole } from "helpers/strings";
import { getCredentials } from 'helpers/auth';
import { getCompaniesRequest, deleteCompanyRequest } from '../api/company'
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";

class Stores extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            companies: null,
            page: 0,
            total: 0,
            limit: 25,
            pages: 0,
            current_page: 0,
            start_page: 0,
            end_page: 9,
            first_page: 0,
            last_page: 0,
            search: '',
            role: '',
            deleteId: ''
        }
        this.timeout = null;
    }

    componentDidMount() {
        const auth = getCredentials();
        if (auth.corporationId) this.getCompanies()
    }

    getCompanies = async () => {
        try {
            const { page, limit, search, role } = this.state
            const auth = getCredentials();
    
            const { companies, total } = await getCompaniesRequest(page, limit, search, role, auth.corporationId)
    
            const pages = Math.ceil(total / limit);
            const last_page = pages - 1;
            const end_page = pages > 10 ? 9 : pages - 1;
    
            this.setState({
                companies,
                total,
                pages,
                last_page,
                end_page
            })        
        } catch (error) {
            console.log('error', error)
        }
    }

    goTo = path => this.props.history.push(path)

    goToPage = async (page) => {
        const { limit, pages, search, role } = this.state;
        const { companies } = await getCompaniesRequest(page, limit, search, role);
        const { start_page, end_page } = calcStartEndPage(page, pages);

        this.setState({
            companies,
            current_page: page,
            start_page,
            end_page
        })
    }

    openConfirmation = (deleteId) => () => {
		this.setState({
			deleteId
		});
		this.deleteConfirmationModal.toggle();
    }
    
    deleteStore = async () => {
		const { deleteId } = this.state;
        await deleteCompanyRequest(deleteId);
        this.getCompanies()
	}

    render () {
        const {
            companies,
            pages,
            current_page,
            end_page,
            start_page,
        } = this.state

        return (
            <div className="content">
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <CardTitle className="d-inline-block" tag="h4">
                                    Stores
                                </CardTitle>
                                <Button className="pull-right mb-3" color="primary" onClick={() => this.goTo('/store/create')}>Create Store</Button>
                                <Button className="pull-right mb-3 mr-3" color="primary" disabled>Link Store</Button>
                                {/* <Input placeholder="Search Users..." value={search} type="text" onChange={this.onChangeSearch}></Input> */}
                            </CardHeader>
                            <CardBody>
                                <Table responsive>
                                    <thead className="text-primary">
                                        <tr>
                                            <th>Name</th>
                                            <th>Owner</th>
                                            <th>Role</th>
                                            <th className="text-right">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {companies && companies.length > 0 
                                        ? companies.map(({
                                            _id, 
                                            name,
                                            role,
                                            owner
                                        }) => (
                                            <tr key={_id}>
                                                <td>{name || 'N/A'}</td>
                                                <td>{owner 
                                                    ? <Link to={`/user?id=${owner._id}`}>{owner.email}</Link>
                                                    : "N/A"
                                                }</td>
                                                <td>{formatRole(role) || 'N/A'}</td>
                                                <td className="text-right">
                                                    <Button
                                                        className="btn-link btn-icon"
                                                        color="success"
                                                        id={`tooltip-view-${_id}`}
                                                        size="sm"
                                                        onClick={() => this.goTo(`/store?id=${_id}`)}
                                                    >
                                                        <i className="tim-icons icon-zoom-split" />
                                                    </Button>
                                                    <UncontrolledTooltip
                                                        delay={0}
                                                        target={`tooltip-view-${_id}`}
                                                    >
                                                        View
                                                    </UncontrolledTooltip>
                                                    <Button
                                                        className="btn-link btn-icon"
                                                        color="info"
                                                        id={`tooltip-edit-${_id}`}
                                                        size="sm"
                                                        onClick={() => this.goTo(`/store/edit?id=${_id}`)}
                                                    >
                                                        <i className="tim-icons icon-pencil" />
                                                    </Button>
                                                    <UncontrolledTooltip
                                                        delay={0}
                                                        target={`tooltip-edit-${_id}`}
                                                    >
                                                        Edit
                                                    </UncontrolledTooltip>
                                                    <Button
                                                        className="btn-link btn-icon"
                                                        color="danger"
                                                        id={`tooltip-delete-${_id}`}
                                                        size="sm"
                                                        onClick={this.openConfirmation(_id)}
                                                    >
                                                        <i className="tim-icons icon-simple-remove" />
                                                    </Button>
                                                    <UncontrolledTooltip
                                                        delay={0}
                                                        target={`tooltip-delete-${_id}`}
                                                    >
                                                        Delete
                                                    </UncontrolledTooltip>
                                                </td>
                                            </tr>
                                        ))
                                        : <tr>
                                            <td colSpan="10">There's no store linked to your corporation</td>
                                        </tr>
                                        }
                                        
                                    </tbody>
                                </Table>
                                {companies?.length > 0 && 
                                    <PaginationComponent
                                        pages={pages}
                                        current_page={current_page}
                                        start_page={start_page}
                                        end_page={end_page}
                                        goToPage={this.goToPage}
                                    />
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <ConfirmationModal
                    ref={el => this.deleteConfirmationModal = el}
                    title="Delete Company?"
                    buttons={[
                        { title: 'Cancel', className: 'btn-primary-override btn btn-primary' },
                        { title: 'Delete', className: 'btn-danger-override btn btn-danger', onClick: this.deleteStore }
                    ]}
                >
                    Are you sure you want to delete this store?
                </ConfirmationModal>
            </div>
        )
    }
}

export default Stores