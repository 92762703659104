import React from 'react';
import CurrencyFormat from 'react-currency-format';

const CurrencyInput = ({ price, name, onChange, placeholder }) => {

	const onPriceChange = (values) => {
		onChange(values.floatValue);
	}

	return (
		<CurrencyFormat
			className="form-control"
			placeholder={placeholder || ''}
			value={price || ''}
			name={name}
			prefix={'$ '}
			thousandSeparator={','}
			decimalSeparator={'.'}
			decimalScale={2}
			fixedDecimalScale={true}
			onValueChange={onPriceChange}
		/>
	)
}

export default CurrencyInput;