import React from "react";

// reactstrap components
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	CardTitle,
	Row,
	Col,
	Table,
	UncontrolledTooltip,
	Input,
	FormGroup,
	Label
} from "reactstrap";
import moment from "moment";
import Async from 'react-select/async';
import { Link } from "react-router-dom";
// Helpers
import { calcStartEndPage } from '../helpers/pagination';
import { formatPrice } from "../helpers/pricing";
import { formatTransactionStatus } from "../helpers/status";
import { formatTransactionType } from "../helpers/transaction";
import { getCredentials } from '../helpers/auth';
// Components
import PaginationComponent from "../components/Custom/Pagination";
// Requests
import { getCorporationTransactionsRequest, getCorporationTransactionsStatsRequest } from '../api/transaction';
import { getCompaniesRequest } from "api/company";
// Constants
import { transactionTypes } from "../constants/types";

class Transactions extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			transactions: null,
			page: 0,
			total: 0,
			limit: 25,
			pages: 0,
			current_page: 0,
			start_page: 0,
			end_page: 9,
			first_page: 0,
			last_page: 0,
			stats: null,
			search: '',
			typeFilter: '',
			start_date: '',
			end_date: '',
			status: '',
			company: '',
			client: ''
		}
		this.timeout = null;
	}

	async componentDidMount() {
		await this.getTransactions();
		await this.getStats();
	}

	getTransactions = async () => {
		const auth = getCredentials();
		const corporation = auth.corporationId;
		const role = auth.role;
		const { limit, page, search, start_date, end_date, status, sellerCompany, filterCompany } = this.state;
		const typeFilter = role === "seller" ? "sale" : "order";
		const transactionsObj = await getCorporationTransactionsRequest(page, limit, search, typeFilter, start_date && start_date.toISOString(), end_date && end_date.toISOString(), status, sellerCompany && sellerCompany.value || '', filterCompany && filterCompany.value || '', corporation);
		const transactions = transactionsObj.transactions;
		const total = transactionsObj.total;
		const pages = Math.ceil(total / limit);
		const last_page = pages - 1;
		const end_page = pages > 10 ? 9 : pages - 1;

		this.setState({
			transactions,
			total,
			pages,
			last_page,
			end_page
		})
	}

	getStats = async () => {
		const auth = getCredentials();
		const corporation = auth.corporationId;
		const stats = await getCorporationTransactionsStatsRequest(corporation);
		this.setState({
			stats
		})
	}

	goTo = path => this.props.history.push(path);

	goToPage = async (page) => {
		const auth = getCredentials();
		const corporation = auth.corporationId;
		const role = auth.role;
		const { limit, search, start_date, end_date, status, sellerCompany, filterCompany } = this.state;
		const typeFilter = role === "seller" ? "sale" : "order";
		const transactionsObj = await getCorporationTransactionsRequest(page, limit, search, typeFilter, start_date && start_date.toISOString(), end_date && end_date.toISOString(), status, sellerCompany && sellerCompany.value || '', filterCompany && filterCompany.value || '', corporation);
		const transactions = transactionsObj.transactions;
		const total = transactionsObj.total;
		const pages = Math.ceil(total / limit);
		const { start_page, end_page } = calcStartEndPage(page, pages);

		this.setState({
			transactions,
			current_page: page,
			start_page: start_page,
			end_page: end_page
		})
	}

	onChangeFilter = (event) => {

		const { name, value } = event.target;
		this.setState({
			[name]: value,
			page: 0
		})
		if (this.timeout) {
			clearTimeout(this.timeout);
		}
		this.timeout = setTimeout(() => {
			this.getTransactions();
		}, 300);
	}
	
	onChangeType = (event) => {
		const { value } = event.target;
		this.setState({
			typeFilter: value
		}, this.getTransactions);
	}

	onChangeDateFilter = (key) => (date) => {
		if(key == 'end_date') {
			date = date.endOf('day');
		}
		this.setState({
			[key]: date
		}, () => {
			if(this.state.start_date && this.state.end_date) {
				this.getTransactions();
			}
		});
	}
	
	isValidStartDate = (current) => {
		return current < moment();
	}

	isValidEndDate = (current) => {
		if(this.state.start_date) {
			return current < moment() && current.diff(this.state.start_date, 'days') > 0;
		} else {
			return current < moment();
		}
	}

	loadCompanies = () => async (text, callback) => {
		if(text) {
			const auth = getCredentials();
			const corporation = auth.corporationId;
			const role = auth.role;
			const companiesResponse = getCompaniesRequest(0, 0, text, role, corporation)
			const companies = companiesResponse.companies.map(company => {
				return {
					data: company,
					label: company.name,
					value: company._id
				}
			})
			callback(companies);
		}
	}

	onSelectChange = (key) => (value) => {
		this.onChangeFilter({ target: { value: value, name: key } });
	}

	render() {

		const {
			transactions,
			pages,
			current_page,
			start_page,
			end_page,
			stats,
			filterCompany
		} = this.state;

		const auth = getCredentials();
		const role = auth.role;
		
		const count = stats ? stats.count : 0;
		const total = stats ? stats.total : 0;

		return (
			<>
				<div className="content">
				<Row>
						<Col xs="12">
							<Card className="card-chart">
								<CardHeader>
									<Row>
										<Col lg="6" md="6">
											<Card className="card-stats">
												<CardBody>
													<Row>
														<Col xs="5">
															<div className="info-icon text-center icon-warning">
																<i className="tim-icons icon-money-coins" />
															</div>
														</Col>
														<Col xs="7">
															<div className="numbers">
																<p className="card-category">{role === "seller" ? "Total Sales" : "Total Orders"}</p>
																<CardTitle tag="h3">{count}</CardTitle>
															</div>
														</Col>
													</Row>
												</CardBody>
												<CardFooter>
													<hr />
													<div className="stats">
														<i className="tim-icons icon-refresh-01" /> Updated now
                                        </div>
												</CardFooter>
											</Card>
										</Col>
										<Col lg="6" md="6">
											<Card className="card-stats">
												<CardBody>
													<Row>
														<Col xs="5">
															<div className="info-icon text-center icon-warning">
																<i className="tim-icons icon-money-coins" />
															</div>
														</Col>
														<Col xs="7">
															<div className="numbers">
																<p className="card-category">{role === "seller" ? "Total Revenue" : "Total Spent"}</p>
																<CardTitle tag="h3">$ {formatPrice(total)}</CardTitle>
															</div>
														</Col>
													</Row>
												</CardBody>
												<CardFooter>
													<hr />
													<div className="stats">
														<i className="tim-icons icon-refresh-01" /> Updated now
                                        			</div>
												</CardFooter>
											</Card>
										</Col>
									</Row>
								</CardHeader>
							</Card>
						</Col>
					</Row>
					<Row>
						<Col md="12">
							<Card>
								<CardHeader>
									<CardTitle tag="h4">Transactions</CardTitle>
									<FormGroup>
										<Async
											value={filterCompany}
											name="filterCompany"
											options={[]}
											loadOptions={this.loadCompanies()}
											className="basic-multi-select"
											classNamePrefix="select"
											onChange={this.onSelectChange('filterCompany')}
											isClearable
											placeholder="Select Store..."
										/>
									</FormGroup>
								</CardHeader>
								<CardBody>
									<Table responsive>
										<thead className="text-primary">
											<tr>
												<th>Date</th>
												<th>Type</th>
												<th>Value</th>
												<th>Store</th>
												<th>{role === "seller" ? "Client" : "Seller"}</th>
                                                <th>Order</th>
												<th className="text-right">Actions</th>
											</tr>
										</thead>
										<tbody>
											{transactions && transactions.length
												? transactions.map((transaction) => {
													return (
														<tr key={transaction._id}>
                                                            <td>{moment(transaction.createdAt).format('MM/DD/YYYY HH:mm')}</td>
															<td>{transaction.type ? formatTransactionType(transaction.type) : "N/A"}</td>
															<td>{transaction.amount ? <>
																<span className={role === "seller" ? 'text-success' : role === "client" ? 'text-danger' : ''}>{role === "seller" ? '+' : role === "client" ? '-' : ''} {`$ ${formatPrice(transaction.amount)}`}</span>
															</> : "N/A"}</td>
															<td>{role === "seller" ? <Link to={`/company?id=${transaction.sellerCompany._id}`}>{transaction.sellerCompany.name}</Link> : role === "client" ? <Link to={`/company?id=${transaction.clientCompany._id}`}>{transaction.clientCompany.name}</Link> : "N/A"}</td>
															<td>{role === "seller" ? <Link to={`/company?id=${transaction.clientCompany._id}`}>{transaction.clientCompany.name}</Link> : role === "client" ? <Link to={`/company?id=${transaction.sellerCompany._id}`}>{transaction.sellerCompany.name}</Link> : "N/A"}</td>
                                                            <td>{transaction.order ? <Link to={`/order?id=${transaction.order._id}`}>{transaction.order._id}</Link> : "N/A"}</td>
															<td className="text-right">
																<Button
																	className="btn-link btn-icon"
																	color="success"
																	id={`tooltip-view-${transaction._id}`}
																	size="sm"
																	onClick={() => this.goTo(`/transaction?id=${transaction._id}`)}
																>
																	<i className="tim-icons icon-zoom-split" />
																</Button>
																<UncontrolledTooltip
																	delay={0}
																	target={`tooltip-view-${transaction._id}`}
																>
																	View
                                                                </UncontrolledTooltip>
															</td>
														</tr>
													)
												})
												: <tr>
													<td colSpan="7">There are no transactions to show</td>
												</tr>
											}
										</tbody>
									</Table>
									{transactions ?.length > 0 &&
										<PaginationComponent
											pages={pages}
											current_page={current_page}
											start_page={start_page}
											end_page={end_page}
											goToPage={this.goToPage}
										/>
                    }
								</CardBody>
							</Card>
						</Col>
					</Row>
				</div>
			</>
		);
	}
}

export default Transactions;
