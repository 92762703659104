import { apiRequest} from './index'

export const signInRequest = (data) => {
    return apiRequest(
      `/auth/signin-corporation`,
      {
        method: 'POST',
        body: JSON.stringify(data)
      },
      true
    )
}

export const signUpRequest = (data) => {
  return apiRequest(
    `/auth/signup-corporation`,
    {
      method: 'POST',
      body: JSON.stringify(data)
    },
    true
  )
}

export const sendResetPasswordEmail = (email) => {
  return apiRequest(
    `/auth/send-reset-password-email`,
    {
      method: 'POST',
      body: JSON.stringify({email})
    },
    true
  )
}

export const resetPassword = (email, code, password, confirmPassword) => {
  return apiRequest(
    `/auth/reset-password`,
    {
      method: 'POST',
      body: JSON.stringify({email, code, password, confirmPassword})
    },
    true
  )
}
