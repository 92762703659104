/* global localStorage */
import moment from "moment";
import { permissions } from "../constants/permissions";

export const clearCredentials = () => {
  localStorage.removeItem("userCredentials");
};

export const setCredentials = (auth) => {
  const now = new Date();

  localStorage.setItem(
    "userCredentials",
    JSON.stringify({
      ...auth,
      expires: moment().add(auth.token_life, "seconds").toDate(),
    })
  );
};

export const getCredentials = () => {
  const credentials = JSON.parse(localStorage.getItem("userCredentials"));

  if (moment().diff(moment(credentials?.expires)) > 0) {
    clearCredentials();
    return;
  }

  return credentials;
};

export const hasPermission = (permission) => {
  if (permission) {
    const user = getCredentials();
    const userPermissions = permissions[user?.role] || [];
    const hasPermission = !!userPermissions.find((perm) => perm === permission);

    return hasPermission;
  }
};

export const getToken = () => {
  const credentials = getCredentials();

  return credentials ? credentials.token : undefined;
};
