import React from "react";

// reactstrap components
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	CardTitle,
	Row,
	Col
} from "reactstrap";
import { getCorporationSettingRequest } from '../api/corporationSetting';

class TierDetails extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			tier: null
		}
	}

	async componentDidMount() {
		const params = new URLSearchParams(this.props.location.search);
		const id = params.get('id');

		if (id) {
			const tier = await getCorporationSettingRequest(id);
			this.setState({ tier })
		} else {
			this.goTo('/settings')
		}
	}

	goTo = path => this.props.history.push(path);

	render() {

		const {
			tier = {}
		} = this.state;

		const {
            tierNumber,
            description,
            discountPercent
		} = tier || {};

		return (
			<>
				<div className="content">
					<Row>
						<Col md="12">
							<Card>
								<CardHeader className="mb-5">
									<CardTitle tag="h3">
										Tier Details
                  					</CardTitle>
									<Button
										className="float-right"
										color="default"
										onClick={() => this.goTo('/settings')}
									>
										Back
                  					</Button>
								</CardHeader>
								<CardBody>
									{!tier
										? <div>Loading...</div>
										: <>
                                            <div className="typography-line">
												<h4>
													<span>Tier Number</span>
													{tierNumber}
												</h4>
											</div>
                                            <div className="typography-line">
												<h4>
													<span>Discount</span>
													{discountPercent * 100}%
												</h4>
											</div>
                                            <div className="typography-line">
												<h4>
													<span>Description</span>
													{description}
												</h4>
											</div>
										</>
									}
								</CardBody>
							</Card>
						</Col>
					</Row>
				</div>
			</>
		);
	}
}

export default TierDetails;
