import React from "react";
import { 
    Button, 
    Card, 
    CardBody, 
    CardHeader, 
    CardTitle, 
    Col, 
    Row, 
    Table
} from "reactstrap";
import { mapSeries } from 'p-iteration';
// Components
import PaginationComponent from "components/Custom/Pagination";
import UpdateTierModal from "components/Modals/UpdateTierModal";
// Helpers
import { calcStartEndPage } from "helpers/pagination";
import { getCredentials } from 'helpers/auth';
// Requests
import { getCompaniesRequest } from 'api/company';
import { updateClientTiersInBulkRequest, getClientTierByClientCompanyIdAndCorporationRequest } from 'api/corporationSetting';
// Assets
import placeholder from 'assets/img/placeholder.jpg';

class Clients extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            clientStores: null,
            page: 0,
            total: 0,
            limit: 25,
            pages: 0,
            current_page: 0,
            start_page: 0,
            end_page: 9,
            first_page: 0,
            last_page: 0,
            search: '',
            selectedClients: {},
            updateTierLoading: false,
			updateTierError: null
        }
    }

    componentDidMount() {
        this.getClients()
    }

    getClients = async () => {
        try {
            const { page, limit, search } = this.state
    
            const { companies, total } = await getCompaniesRequest(page, limit, search, "client", null)
    
            const pages = Math.ceil(total / limit);
            const last_page = pages - 1;
            const end_page = pages > 10 ? 9 : pages - 1;

            const companiesWithTiers = await this.getCompaniesTiers(companies);
    
            this.setState({
                clientStores: companiesWithTiers,
                total,
                pages,
                last_page,
                end_page
            })        
        } catch (error) {
            console.log('error', error)
        }
    }

    getCompaniesTiers = async (companies) => {
        const auth = getCredentials();

        const updatedCompanies = await mapSeries(companies, async(company) => {
            // Check if a tier exists for company
            // If yes, set tier from instance
            // If not, set tier 1;
            let tierInstance = await getClientTierByClientCompanyIdAndCorporationRequest(company._id, auth.corporationId);
            if (tierInstance) {
                company.tier = tierInstance.tierNumber;
            } else {
                company.tier = "1"
            }
            return company;
        })

        return updatedCompanies;

    }

    setSelectedClient = (clientId) => (event) => {
		const { checked } = event.target;
		this.setState((prevState) => ({
		  selectedClients: {
			...prevState.selectedClients,
			[clientId]: checked
		  }
		}));
	}

    getSelectedClientIds = () => {
		const { selectedClients = {} } = this.state;
		return Object.keys(selectedClients).filter(key => selectedClients[key]);
	}

    openUpdateTierModal = () => {
		this.updateTierModal.toggle();
	}

    updateTiers = async (targetTier) => {
        
        try {

            this.setState({
                updateTierLoading: true,
                updateTierError: null
            });

            const auth = getCredentials();

            const data = {
                _id: auth.corporationId,
                targetTier,
                companyIdList: this.getSelectedClientIds()
            }

            updateClientTiersInBulkRequest(data)
            this.updateTierModal.toggle();

            this.setState({
                updateTierLoading: false,
            })

        } catch (error) {
            this.setState({
                updateTierLoading: false,
                updateTierError: error.message
            })
        }
        
    }

    goTo = path => this.props.history.push(path)

    goToPage = async (page) => {
        const { limit, pages, search, role } = this.state;
        const { companies } = await getCompaniesRequest(page, limit, search, "client");
        const { start_page, end_page } = calcStartEndPage(page, pages);

        const companiesWithTiers = await this.getCompaniesTiers(companies);

        this.setState({
            clientStores: companiesWithTiers,
            current_page: page,
            start_page,
            end_page
        })
    }

    render () {
        const {
            clientStores,
            pages,
            current_page,
            end_page,
            start_page,
            selectedClients,
            updateTierLoading,
			updateTierError,
        } = this.state

        return (
            <div className="content">
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <CardTitle className="d-inline-block" tag="h4">
                                    Client Stores
                                </CardTitle>
                                <Row>
                                    <Col sm='12' className='d-flex justify-content-end mt-3'>											
                                        <Button disabled={this.getSelectedClientIds().length ? false : true} color="primary" onClick={this.openUpdateTierModal}>Update Tier</Button>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Table responsive>
                                    <thead className="text-primary">
                                        <tr>
                                            <th></th>
                                            <th>Logo</th>
                                            <th>Name</th>
                                            <th>Corporation</th>
                                            <th>Tier</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {clientStores && clientStores.length > 0 
                                        ? clientStores.map(({
                                            _id, 
                                            name,
                                            image,
                                            corporation,
                                            tier
                                        }) => (
                                            <tr key={_id}>
                                                <td>
                                                    <input type="checkbox" checked={!!selectedClients[_id]} onChange={this.setSelectedClient(_id)}></input>
                                                </td>
                                                <td>{image
                                                        ? <a href={image} target="_blank"><img className="product-preview" src={image} /></a> 
                                                        : <img className="product-preview" src={placeholder} />
                                                    }
                                                </td>
                                                <td>{name || 'N/A'}</td>
                                                <td>{corporation?.name || 'N/A'}</td>
                                                <td>Tier {tier}</td>
                                            </tr>
                                        ))
                                        : <tr>
                                            <td colSpan="10">There are no results to show</td>
                                        </tr>
                                        }
                                        
                                    </tbody>
                                </Table>
                                {clientStores?.length > 0 && 
                                    <PaginationComponent
                                        pages={pages}
                                        current_page={current_page}
                                        start_page={start_page}
                                        end_page={end_page}
                                        goToPage={this.goToPage}
                                    />
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <UpdateTierModal
                    onSave={this.updateTiers}
                    ref={(c) => this.updateTierModal = c}
                    error={updateTierError}
                    loading={updateTierLoading}
                    selectedClients={selectedClients}
                />
            </div>
        )
    }
}

export default Clients