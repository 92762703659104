import React from "react";
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  UncontrolledAlert
} from "reactstrap";
// Requests
import { signUpRequest } from '../api/auth';

class Register extends React.Component {
  state = {
    ownerFirstName: '',
    ownerLastName: '',
    ownerPhoneNumber: '',
    email: '',
    corporationName: '',
    role: 'seller',
    password: '',
    confirmPassword: '',
    loading: false,
    apiError: null,
    captchaIsSolved: false
  };

  componentDidMount() {
    document.body.classList.toggle("register-page");
  }

  componentWillUnmount() {
    document.body.classList.toggle("register-page");
  }

  onFormInput = (e) => {
    const { name, value } = e.target;

    this.setState({
        [name]: value
    })
  }

  goTo = path => this.props.history.push(path);

  onSubmit = async () => {
    this.setState({
        loading: true,
        apiError: null
    })

    const { 
      ownerFirstName,
      ownerLastName,
      ownerPhoneNumber,
      email,
      corporationName,
      role,
      password,
      confirmPassword,
    } = this.state;

    const data = {
      ownerFirstName,
      ownerLastName,
      ownerPhoneNumber,
      email,
      corporationName,
      role,
      password,
      confirmPassword,
    }

    try {

      await signUpRequest(data);
      this.handleSignUpSuccess();

    } catch (error) {
      this.setState({
        apiError: error.message
      })
    }
  }

  handleSignUpSuccess = () => {

    this.setState({
        loading: false,
        apiError: null,
        ownerFirstName: '',
        ownerLastName: '',
        ownerPhoneNumber: '',
        email: '',
        corporationName: '',
        role: 'seller',
        password: '',
        confirmPassword: '',
    }, () => this.props.history.push('/login'))
  }

  render() {

    const { 
      ownerFirstName,
      ownerLastName,
      ownerPhoneNumber,
      email,
      corporationName,
      role,
      password,
      confirmPassword,
      apiError,
      loading
    } = this.state;

    return (
      <>
        <div className="content">
          <Container>
            <Row>
              <Col className="ml-auto" md="5">
                <div className="info-area info-horizontal mt-5">
                  <div className="icon icon-warning">
                    <i className="tim-icons icon-bank" />
                  </div>
                  <div className="description">
                    <h3 className="info-title">Made for Corporations</h3>
                    <p className="description">
                      Register your corporation to unlock full control over your chain of stores on Construction Run. Everything you need to manage your corporation seamlessly is here.
                    </p>
                  </div>
                </div>
                <div className="info-area info-horizontal mt-5">
                  <div className="icon icon-primary">
                    <i className="tim-icons icon-money-coins" />
                  </div>
                  <div className="description">
                    <h3 className="info-title">Your Business Command Center</h3>
                    <p className="description">
                      Manage stores, dive deep into your finances, and adjust settings all from one integrated platform.
                    </p>
                  </div>
                </div>
                <div className="info-area info-horizontal mt-5">
                  <div className="icon icon-info">
                    <i className="tim-icons icon-settings-gear-63" />
                  </div>
                  <div className="description">
                    <h3 className="info-title">High Level Customization</h3>
                    <p className="description">
                      Manage your corporation's settings as well as your partner list and much more.
                    </p>
                  </div>
                </div>
              </Col>
              <Col className="mr-auto" md="7">
                <Card className="card-register card-white">
                  <CardHeader>
                    <CardImg
                      alt="..."
                      src={require("assets/img/card-primary.png")}
                    />
                    <CardTitle tag="h4">Register</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Form className="form">
                      <InputGroup
                        className={classnames({
                          "input-group-focus": this.state.corporationNameFocus
                        })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="tim-icons icon-bank" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Corporation Name"
                          type="text"
                          onFocus={e => this.setState({ corporationNameFocus: true })}
                          onBlur={e => this.setState({ corporationNameFocus: false })}
                          name="corporationName"
                          value={corporationName}
                          onChange={e => this.onFormInput(e)}
                        />
                      </InputGroup>
                      <InputGroup
                        className={classnames({
                          "input-group-focus": this.state.ownerFirstNameFocus
                        })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="tim-icons icon-single-02" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Owner First Name"
                          type="text"
                          onFocus={e => this.setState({ ownerFirstNameFocus: true })}
                          onBlur={e => this.setState({ ownerFirstNameFocus: false })}
                          name="ownerFirstName"
                          value={ownerFirstName}
                          onChange={e => this.onFormInput(e)}
                        />
                      </InputGroup>
                      <InputGroup
                        className={classnames({
                          "input-group-focus": this.state.ownerLastNameFocus
                        })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="tim-icons icon-single-02" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Owner Last Name"
                          type="text"
                          onFocus={e => this.setState({ ownerLastNameFocus: true })}
                          onBlur={e => this.setState({ ownerLastNameFocus: false })}
                          name="ownerLastName"
                          value={ownerLastName}
                          onChange={e => this.onFormInput(e)}
                        />
                      </InputGroup>
                      <InputGroup
                        className={classnames({
                          "input-group-focus": this.state.ownerPhoneNumberFocus
                        })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="tim-icons icon-mobile" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Owner Phone Number"
                          type="text"
                          onFocus={e => this.setState({ ownerPhoneNumberFocus: true })}
                          onBlur={e => this.setState({ ownerPhoneNumberFocus: false })}
                          name="ownerPhoneNumber"
                          value={ownerPhoneNumber}
                          onChange={e => this.onFormInput(e)}
                        />
                      </InputGroup>
                      <InputGroup
                        className={classnames({
                          "input-group-focus": this.state.emailFocus
                        })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="tim-icons icon-email-85" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Email"
                          type="text"
                          onFocus={e => this.setState({ emailFocus: true })}
                          onBlur={e => this.setState({ emailFocus: false })}
                          name="email"
                          value={email}
                          onChange={e => this.onFormInput(e)}
                        />
                      </InputGroup>
                      <InputGroup
                        className={classnames({
                          "input-group-focus": this.state.passFocus
                        })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="tim-icons icon-lock-circle" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Password"
                          type="password"
                          onFocus={e => this.setState({ passFocus: true })}
                          onBlur={e => this.setState({ passFocus: false })}
                          name="password"
                          value={password}
                          onChange={e => this.onFormInput(e)}
                        />
                      </InputGroup>
                      <InputGroup
                        className={classnames({
                          "input-group-focus": this.state.passFocus
                        })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="tim-icons icon-lock-circle" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Confirm Password"
                          type="password"
                          onFocus={e => this.setState({ passFocus: true })}
                          onBlur={e => this.setState({ passFocus: false })}
                          name="confirmPassword"
                          value={confirmPassword}
                          onChange={e => this.onFormInput(e)}
                        />
                      </InputGroup>
                      <FormGroup check className="text-left">
                        <Label check>
                          <Input type="checkbox" />
                          <span className="form-check-sign" />I agree to the{" "}
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            terms and conditions
                          </a>
                          .
                        </Label>
                      </FormGroup>
                    </Form>
                  </CardBody>
                  <CardFooter>
                    {apiError &&
                      <UncontrolledAlert color="primary" fade={false}>
                        <span>{apiError}</span>
                      </UncontrolledAlert>
                    }
                    <Button
                      block
                      className="mb-3"
                      color="primary"
                      onClick={() => this.onSubmit()}
                      size="lg"
                    >
                      Get Started
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default Register;
