import React from "react";
// reactstrap components
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	CardTitle,
	Row,
	Col,
	FormGroup,
	Input,
	Label,
	Table,
	UncontrolledTooltip
} from "reactstrap";
import Async from 'react-select/async';
// Components
import CurrencyInput from 'components/CurrencyInput/CurrencyInput';
// Helpers
import { calcStartEndPage } from "helpers/pagination";
// Requests
import { createCorporationSettingRequest } from "api/corporationSetting";
import { getProductsRequest } from "api/product";
import { getCompaniesRequest } from "api/company";
// Assets
import productPlaceholder from 'assets/img/product-placeholder.jpg';

class CustomPricesCreate extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			corporationId: null,
			clientCompany: null,
			error: null,
			products: [],
			addedProducts: [],
			selectedProduct: null,
			selectedPrice: null,
			page: 0,
			limit: 25,
			search: "",
			isAddingProduct: false,
			isSaving: false
		}
	}

	async componentDidMount() {
		const params = new URLSearchParams(this.props.location.search);
		const corporationId = params.get('corporationId');
		
		this.setState({
			corporationId
		})
	}

	loadCompanies = async (text, callback) => {
		if(text) {
			const companiesResponse = await getCompaniesRequest(0, 10, text, "client", "")
			const companies = companiesResponse.companies.map(company => {
				return {
					data: company,
					label: company.name,
					value: company._id
				}
			});
			callback(companies);
		}
	}

	loadProducts = async (text, callback) => {
		if(text) {
			const productsResponse = await getProductsRequest(0, 0, text, '', '');
			const products = productsResponse.products.map(product => {
				return {
					data: product,
					label: 
					<div>
						<img 
							className="mr-3" 
							height="30px" 
							width="30px" 
							src={product.images[0] ? product.images[0] : productPlaceholder}>
						</img>
							{product.name}
					</div>,
					value: product._id
				}
			});
			callback(products);
		}
	}


	onSelectChange = (key) => (value) => {
		this.setState({
			[key]: value
		});
	}

	onFormInput = (e) => {
		const { name, value } = e.target;

		this.setState({
		[name]: value
		})
	}

	onChangeFilter = (event) => {
		const { name, value } = event.target;
		this.setState({
		  [name]: value,
		  page: 0,
		});
		if (this.timeout) {
		  clearTimeout(this.timeout);
		}
		this.timeout = setTimeout(() => {
		  this.fetchProducts();
		}, 300);
	};

	goTo = path => this.props.history.push(path);

	addProduct = () => {

		this.setState({
			isAddingProduct: true
		})

		const {
			selectedProduct,
			selectedPrice,
			addedProducts
		} = this.state;

		const obj = {
			product: selectedProduct.data,
			price: selectedPrice
		};

		addedProducts.push(obj);

		this.setState({
			addedProducts,
			isAddingProduct: false,
			selectedProduct: null,
			selectedPrice: null
		})
	}

	save = async () => {

		this.setState({
			error: null,
			isSaving: true
		})

		const {
			corporationId,
			clientCompany,
			addedProducts
		} = this.state;

		const products = addedProducts.map((entry) => {
			return {
				product: entry.product._id,
				price: entry.price
			}
		})

		const data = {
			type: "customPrice",
			products,
			corporationId,
			clientCompany: clientCompany.value
		};

		try {

			await createCorporationSettingRequest(data);

			this.setState({
				error: null,
				isSaving: false
			})

			this.goTo('/custom-prices');

		} catch (err) {

			this.setState({
				error: err.message || 'There was an error creating Custom Price',
				isSaving: false
			})
		}
	}

	goToPage = async (page) => {
		const { limit, pages, search } = this.state;
		const productsResponse = await getProductsRequest(page, limit, search);
		const products = productsResponse.products;
		const { start_page, end_page } = calcStartEndPage(page, pages);
	
		this.setState({
		  products,
		  current_page: page,
		  start_page: start_page,
		  end_page: end_page,
		});
	};

	render() {

		const {
			clientCompany,
			isAddingProduct,
			addedProducts,
			selectedProduct,
			selectedPrice,
			error,
			isSaving
		} = this.state;

		const isButtonDisabled = !clientCompany || !addedProducts || (addedProducts && addedProducts.length === 0) || isSaving

		return (
			<>
				<div className="content">
					<Row>
						<Col md="12">
							<Card>
								<CardHeader className="mb-5">
									<CardTitle tag="h3">
										Create Custom Price
                  					</CardTitle>
									<Button
										className="float-right"
										color="default"
										onClick={() => this.goTo('/settings')}
									>
										Back
 					        		</Button>
								</CardHeader>
								<CardBody>
									<>
										<h4 className="mb-1">1. Select Company</h4>
										<p className="mb-3">Select the company which you want to set Custom Prices for</p>
										<FormGroup>
											<Async
												value={clientCompany}
												name="clientCompany"
												options={[]}
												loadOptions={this.loadCompanies}
												className="basic-multi-select"
												classNamePrefix="select"
												onChange={this.onSelectChange('clientCompany')}
												isClearable
												placeholder="Start typing to load options..."
											/>
										</FormGroup>

										<h4 className="my-3">2. Select Products to be included</h4>

										<Card className='detail-card'>
											<CardHeader>
                                                <h3>Product Info</h3>
                                            </CardHeader>

											<CardBody>
												<FormGroup>
													<Label>Select Product</Label>
													<Async
														value={selectedProduct}
														name="selectedProduct"
														options={[]}
														loadOptions={this.loadProducts}
														className="basic-multi-select"
														classNamePrefix="select"
														onChange={this.onSelectChange('selectedProduct')}
														isClearable
														placeholder="Start typing to load products..."
													/>
												</FormGroup>

												<FormGroup>
													<Label>Select Price</Label>
													<CurrencyInput 
														name="selectedPrice"
														price={selectedPrice || ''}
														onChange={(value) => this.setState({ selectedPrice: value })}
														placeholder="Custom Price for this client"
													/>
												</FormGroup> 

												<Button
													onClick={this.addProduct}
													disabled={isAddingProduct}
													className="d-flex justify-content-around"
													color="success"
													size="sm"
												>
													Add Product 
												</Button>

											</CardBody>
										</Card>

										{addedProducts && addedProducts.length
											? <>
												<h4 className="my-3">3. Review list of products and their respective prices</h4>
												<Table responsive>
													<thead className="text-primary">
														<tr>
															<th>Image</th>
															<th>Name</th>
															<th>Custom Price</th>
															<th className="text-right">Actions</th>
														</tr>
													</thead>
													<tbody>
														{addedProducts.map((addedProductObj, index) => {
															return (
																<tr key={addedProductObj.product._id}>
																	<td>
																		{addedProductObj.product.images && addedProductObj.product.images.length > 0
																			? <img className="product-preview" src={addedProductObj.product.images[0]} />
																			: <img className="product-preview" src={productPlaceholder} />
																		}
																	</td>
																	<td>{addedProductObj.product.name}</td>
																	<td>{addedProductObj.price}</td>
																	<td className="text-right">
																		<Button
																			className="btn-link btn-icon"
																			color="danger"
																			id={`tooltip-delete-${addedProductObj.product._id}`}
																			size="sm"
																			onClick={() => this.onRemoveFromAdded(index)}
																		>
																			<i className="tim-icons icon-simple-remove" />
																		</Button>
																		<UncontrolledTooltip
																			delay={0}
																			target={`tooltip-delete-${addedProductObj.product._id}`}
																		>
																			Remove
																		</UncontrolledTooltip>
																	</td>
																</tr>
															)
														})}
													</tbody>
												</Table>

												<h4 className="my-3">4. If everything is correct, click the button below to save the configuration</h4>

												{error ? <div className="alert alert-danger mt-3">
														<span>{error}</span>
													</div> : null
												}
												<Button 
													color="primary" 
													block={false} 
													className="mb-4 mt-4" 
													onClick={this.save}
													disabled={isButtonDisabled}
												>
													Create Custom Price
												</Button>
											</>
											: null
										}
									</>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</div>
			</>
		);
	}
}

export default CustomPricesCreate;

