import React from "react";

// reactstrap components
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	CardTitle,
	Row,
	Col
} from "reactstrap";
import moment from 'moment';
// Helpers
import productPlaceholder from 'assets/img/product-placeholder.jpg';
// Requests
import { getCorporationCustomPriceByIdRequest } from "api/corporation";
import { formatPrice } from "helpers/pricing";

class CustomPriceDetails extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			customPrice: null
		}
	}

	async componentDidMount() {
		const params = new URLSearchParams(this.props.location.search);
		const id = params.get('id');

		if (id) {
			const customPrice = await getCorporationCustomPriceByIdRequest(id);
			this.setState({ customPrice })
		} else {
			this.goTo('/custom-prices')
		}
	}

	goTo = path => this.props.history.push(path);

	render() {

		const {
			customPrice
		} = this.state;

		const {
			_id,
            clientCompany,
            products,
			createdAt,
			updatedAt
		} = customPrice || {};

		return (
			<>
				<div className="content">
					<Row>
						<Col md="12">
							<Card>
								<CardHeader className="mb-5">
									<CardTitle tag="h3">
										Custom Price Details
                  					</CardTitle>
									<Button
										className="float-right"
										color="default"
										onClick={() => this.goTo('/custom-prices')}
									>
										Back
                  					</Button>
								</CardHeader>
								<CardBody>
									{!customPrice
										? <div>Loading...</div>
										: <>
											<div className="typography-line">
												<h4>
													<span>ID</span>
													{_id}
												</h4>
											</div>
											<div className="typography-line">
												<h4>
													<span>Client Company</span>
													{clientCompany.name}
												</h4>
											</div>
                                            <Card className='detail-card p-2'>
                                                <CardHeader>
                                                    <h3>Products</h3>
                                                </CardHeader>
                                                <CardBody>
                                                    {
                                                        products && products.length && products.map(({product, price}) => (
                                                            <tr className="d-flex align-items-center my-1">
                                                            <div className="my-1">
                                                            {product.images && product.images.length > 0
                                                                ? <a href={product.images[0]} target="_blank"><img className="product-preview" src={product.images[0]} /></a> 
                                                                : <img className="product-preview" src={productPlaceholder} />
                                                            }
                                                            </div>
                                                            <p className="mx-4">{product?.name}</p>
                                                            <p className="ml-auto">$ {formatPrice(price)}</p>
                                                        </tr>
                                                        ))
                                                    }
                                                </CardBody>
                                            </Card>
											<div className="typography-line">
												<h4>
													<span>Created At</span>
													{createdAt ? moment(createdAt).format("YYYY-MM-DD HH:mm:ss") : "N/A"}
												</h4>
											</div>
											<div className="typography-line">
												<h4>
													<span>Updated At</span>
													{updatedAt ? moment(updatedAt).format("YYYY-MM-DD HH:mm:ss") : "N/A"}
												</h4>
											</div>
										</>
									}
								</CardBody>
							</Card>
						</Col>
					</Row>
				</div>
			</>
		);
	}
}

export default CustomPriceDetails;
