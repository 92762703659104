import React from "react";

// reactstrap components
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	CardTitle,
	Row,
	Col
} from "reactstrap";
import moment from 'moment';
import { getTransactionByIdRequest } from '../api/transaction';
import { formatTransactionType } from "helpers/transaction";
import { formatPrice } from "helpers/pricing";
import { formatTransactionStatus } from "helpers/status";
import { Link } from "react-router-dom";

class TransactionDetails extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			transaction: null
		}
	}

	async componentDidMount() {
		const params = new URLSearchParams(this.props.location.search);
		const id = params.get('id');

		if (id) {
			const transaction = await getTransactionByIdRequest(id);
			this.setState({ transaction })
		} else {
			this.goTo('/transactions')
		}
	}

	goTo = path => this.props.history.push(path);

	render() {

		const {
			transaction = {}
		} = this.state;

		const {
			_id,
			type,
			amount,
			isInbound,
			status,
			client,
			clientCompany,
			sellerCompany,
			order,
			createdAt,
			updatedAt
		} = transaction || {};

		return (
			<>
				<div className="content">
					<Row>
						<Col md="12">
							<Card>
								<CardHeader className="mb-5">
									<CardTitle tag="h3">
										Transaction Details
                  					</CardTitle>
									<Button
										className="float-right"
										color="default"
										onClick={() => this.goTo('/transactions')}
									>
										Back
                  					</Button>
								</CardHeader>
								<CardBody>
									{!transaction
										? <div>Loading...</div>
										: <>
											<div className="typography-line">
												<h4>
													<span>ID</span>
													{_id}
												</h4>
											</div>
											<div className="typography-line">
												<h4>
													<span>Type</span>
													{type ? formatTransactionType(type) : "N/A"}
												</h4>
											</div>
											<div className="typography-line">
												<h4>
													<span>Amount</span>
													{amount ? <>
															<div className={transaction.isInbound === true ? 'text-success' : transaction.isInbound === false ? 'text-danger' : ''}>{isInbound === true ? '+' : isInbound === false ? '-' : ''} {`$ ${formatPrice(amount)}`}</div>
														</> : "N/A"
													}
												</h4>
											</div>
											<div className="typography-line">
												<h4>
													<span>Status</span>
													{status ? formatTransactionStatus(status) : "N/A"}
												</h4>
											</div>
											<div className="typography-line">
												<h4>
													<span>Client</span>
													{clientCompany ? <Link to={`/company?id=${clientCompany._id}`}>{clientCompany.name}</Link> : "N/A"}
												</h4>
											</div>
											<div className="typography-line">
												<h4>
													<span>Ordered By</span>
													{client ? <Link to={`/user?id=${client.user}`}>{client.firstName} {client.lastName}</Link> : "N/A"}
												</h4>
											</div>
											<div className="typography-line">
												<h4>
													<span>Seller</span>
													{sellerCompany ? <Link to={`/company?id=${sellerCompany._id}`}>{sellerCompany.name}</Link> : "N/A"}
												</h4>
											</div>
											<div className="typography-line">
												<h4>
													<span>Order</span>
													{order ? <Link to={`/order?id=${order._id}`}></Link> : "N/A"}
												</h4>
											</div>
											<div className="typography-line">
												<h4>
													<span>Created At</span>
													{createdAt ? moment(createdAt).format("YYYY-MM-DD HH:mm:ss") : "N/A"}
												</h4>
											</div>
											<div className="typography-line">
												<h4>
													<span>Updated At</span>
													{updatedAt ? moment(updatedAt).format("YYYY-MM-DD HH:mm:ss") : "N/A"}
												</h4>
											</div>
										</>
									}
								</CardBody>
							</Card>
						</Col>
					</Row>
				</div>
			</>
		);
	}
}

export default TransactionDetails;
