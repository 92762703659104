export const permissions = {
    'seller': [
        'ViewDashboard',
        'ViewStores',
        'ViewFinancial',
        'ViewClients',
        'ViewCustomPrices',
        'ViewTransfers',
        'ViewProducts'
    ],
    'client': [
        'ViewDashboard',
        'ViewStores',
        'ViewFinancial',
        'ViewDeals'
    ]
}