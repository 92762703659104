import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Row,
  Col,
  Button,
  Table,
  UncontrolledTooltip
} from "reactstrap";
import moment from "moment";
// Components
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
// Helpers
import { getCredentials } from 'helpers/auth';
import { formatTransferStatus } from 'helpers/status';
// Requests
import { getTransfersRequest, deleteTransferRequest } from 'api/transfer'

class Transfers extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            transfers: null,
            deleteId: ''
        }
    }

    componentDidMount() {
        const auth = getCredentials();
        if (auth.corporationId) this.getTransfers()
    }

    getTransfers = async () => {
        try {
            const auth = getCredentials();
    
            const { transfers } = await getTransfersRequest(0, 0, '', '', auth.corporationId, '', '')
    
            this.setState({
                transfers
            })        
        } catch (error) {
            console.log('error', error)
        }
    }


    goTo = path => this.props.history.push(path)

    openConfirmation = (deleteId) => () => {
		this.setState({
			deleteId
		});
		this.deleteConfirmationModal.toggle();
    }

    deleteTransfer = async () => {
		const { deleteId } = this.state;
        await deleteTransferRequest(deleteId);
        this.getTransfers()
	}

    render() {

        const {
            transfers
        } = this.state;

        const auth = getCredentials();

        return (
        <>
            <div className="content">
            <Row>
                <Col xs="12">
                    <Card className="card-chart">
                        <CardHeader className="mb-5">
                            <CardTitle tag="h3">
                                Transfers
                            </CardTitle>
                            <Button className="pull-right mb-3" color="primary" onClick={() => this.goTo(`/transfers/create?corporationId=${auth.corporationId}`)}>Create New</Button>
                        </CardHeader>
                        <CardBody>
                            <Table responsive>
                                <thead className="text-primary">
                                    <tr>
                                        <th>Date</th>
                                        <th>Origin</th>
                                        <th>Destination</th>
                                        <th>Status</th>
                                        <th>Delivery Fee</th>
                                        <th className="text-right">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {transfers && transfers.length
                                    ? transfers.map(({
                                            _id,
                                            createdAt,
                                            originCompany,
                                            destinationCompany,
                                            status,
                                            deliveryFee
                                        }, index) => (
                                            <tr key={_id}>
                                                <td>{moment(createdAt).format('MM/DD/YYYY')}</td>
                                                <td>{originCompany?.name}</td>
                                                <td>{destinationCompany?.name}</td>
                                                <td>{formatTransferStatus(status)}</td>
                                                <td>$ {deliveryFee.toFixed(2)}</td>
                                                <td className="text-right">
                                                    <Button
                                                        className="btn-link btn-icon"
                                                        color="success"
                                                        id={`tooltip-view-${_id}`}
                                                        size="sm"
                                                        onClick={() => this.goTo(`/transfer?id=${_id}`)}
                                                    >
                                                        <i className="tim-icons icon-zoom-split" />
                                                    </Button>
                                                    <UncontrolledTooltip
                                                        delay={0}
                                                        target={`tooltip-view-${_id}`}
                                                    >
                                                        View
                                                    </UncontrolledTooltip>
                                                    {/*<Button
                                                        className="btn-link btn-icon"
                                                        color="danger"
                                                        id={`tooltip-delete-${_id}`}
                                                        size="sm"
                                                        onClick={this.openConfirmation(_id)}
                                                    >
                                                        <i className="tim-icons icon-simple-remove" />
                                                    </Button>
                                                    <UncontrolledTooltip
                                                        delay={0}
                                                        target={`tooltip-delete-${_id}`}
                                                    >
                                                        Delete
                                                    </UncontrolledTooltip>*/}
                                                </td>
                                            </tr>
                                        ))
                                    :   <tr>You haven't created any transfers yet</tr>}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <ConfirmationModal
                ref={el => this.deleteConfirmationModal = el}
                title="Delete Entry?"
                buttons={[
                    { title: 'Cancel', className: 'btn-primary-override btn btn-primary' },
                    { title: 'Delete', className: 'btn-danger-override btn btn-danger', onClick: this.deleteTransfer }
                ]}
            >
                Are you sure you want to delete this entry?
            </ConfirmationModal>
            </div>
        </>
        );
    }
}

export default Transfers;
