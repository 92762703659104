import { getToken } from "helpers/auth";

/* global fetch */
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export const baseRequest = (pathRoute, pathParam, method, body) => {
  return apiRequest(
    `/${pathRoute}${pathParam ? `/${pathParam}` : ""}`,
    body ? { method, body: JSON.stringify(body) } : { method },
    true
  );
};

export const request = (endpoint, options) =>
  fetch(`${API_ENDPOINT}/api${endpoint}`, options).then((response) => {
    if (response.status >= 200 && response.status < 300) {
      if(options.responseType !== 'blob')
        return response.json()
      else 
        return response.blob();
    } else if (response.status === 403) {
      window.location.reload()
    }  else {
      return response.json().then((data) => {
        const responseObj = { ...data, status: response.status };
        throw responseObj;
      });
    }
});

export const apiRequest = (endpoint, options = {}) => {
  const config = {
    method: "GET",
    ...options,
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "auth-token": getToken(),
      ...options.headers,
    },
  };

  return request(endpoint, config);
};

export const badRequestResponse = (message) =>
  new Promise((resolve, reject) => {
    return reject({ status: 400, message });
  });
