import { apiRequest} from './index'

export const getCorporationTransactionsRequest = (page, limit, search, type, start_date, end_date, status, sellerCompany, clientCompany, corporation) => {
  return apiRequest(
    `/corporations/transactions?page=${page}&limit=${limit}&search=${search}&type=${type}&start_date=${start_date}&end_date=${end_date}&status=${status}&sellerCompany=${sellerCompany}&clientCompany=${clientCompany}&corporation=${corporation}`,
    {
      method: 'GET'
    },
    true
  )
}

export const getTransactionByIdRequest = (id) => {
  return apiRequest(
    `/admin/transactions/${id}`,
    {
      method: 'GET'
    },
    true
  )
}

export const getCorporationTransactionsStatsRequest = (id) => {
  return apiRequest(
    `/corporations/transaction-stats/${id}`,
    {
      method: 'GET'
    },
    true
  )
}