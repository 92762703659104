import React from "react";
import classnames from "classnames";
import { NavLink } from "react-router-dom";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  UncontrolledAlert,
  Label
} from "reactstrap";
// Helpers
import { setCredentials } from '../helpers/auth';
// Requests
import { signInRequest } from '../api/auth';

class Login extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      loading: false,
      apiError: null,
      captchaIsSolved: false
    }
  }

  componentDidMount() {
    document.body.classList.toggle("login-page");
  }

  componentWillUnmount() {
    document.body.classList.toggle("login-page");
  }

  onFormInput = (e) => {
    const { name, value } = e.target;

    this.setState({
        [name]: value
    })
  }

  goTo = path => this.props.history.push(path);

  onSubmit = async () => {
    this.setState({
        loading: true,
        apiError: null
    })

    const { email, password } = this.state;

    const data = {
      email,
      password
    }

    try {

      const auth = await signInRequest(data);
      this.handleLoginSuccess(auth);

    } catch (error) {
      this.setState({
        apiError: error.message
      })
    }
  }

  handleLoginSuccess = (auth) => {

    setCredentials(auth)

    this.setState({
        loading: false,
        email: '',
        password: '',
        apiError: null
    }, () => this.props.history.push('/dashboard'))
  }

  render() {

    const { email, password, apiError } = this.state;

    return (
      <>
        <div className="content">
          <Container>
            <Col className="ml-auto mr-auto" lg="4" md="6">
              <Form className="form">
                <Card className="card-login card-white">
                  <CardHeader>
                    <img
                      alt="..."
                      src={require("assets/img/card-primary.png")}
                    />
                    <CardTitle tag="h1">Sign In</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <InputGroup
                      className={classnames({
                        "input-group-focus": this.state.emailFocus
                      })}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="tim-icons icon-email-85" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email"
                        type="text"
                        name="email"
                        onFocus={e => this.setState({ emailFocus: true })}
                        onBlur={e => this.setState({ emailFocus: false })}
                        value={email}
                        onChange={e => this.onFormInput(e)}
                      />
                    </InputGroup>
                    <InputGroup
                      className={classnames({
                        "input-group-focus": this.state.passFocus
                      })}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="tim-icons icon-lock-circle" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type="password"
                        name="password"
                        onFocus={e => this.setState({ passFocus: true })}
                        onBlur={e => this.setState({ passFocus: false })}
                        value={password}
                        onChange={e => this.onFormInput(e)}
                      />
                    </InputGroup>
                  </CardBody>
                  <CardFooter>
                    {apiError &&
                      <UncontrolledAlert color="primary" fade={false}>
                        <span>{apiError}</span>
                      </UncontrolledAlert>
                    }
                    <Button
                      block
                      className="mb-3"
                      color="primary"
                      onClick={() => this.onSubmit()}
                      size="lg"
                    >
                      Continue
                    </Button>
                    <Label style={{ display: 'flex', justifyContent: 'center', gap: '2px' }}>
                      <span className="form-check-sign" />Forgot your password?
                      <NavLink to="/send-email">
                        Click here
                      </NavLink>
                    </Label>
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Container>
        </div>
      </>
    );
  }
}

export default Login;
