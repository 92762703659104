import React from "react";

// reactstrap components
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	CardTitle,
	Row,
	Col,
	UncontrolledTooltip,
	Table
} from "reactstrap";
import moment from 'moment';
// Helpers
import productPlaceholder from 'assets/img/product-placeholder.jpg';
// Requests
import { getCorporationCustomPriceByIdRequest } from "api/corporation";
import { formatPrice } from "helpers/pricing";
import CustomPriceModal from "components/CustomPriceModal/CustomPriceModal";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import { deleteProductFromCustomPriceRequest } from "api/corporationSetting";
import { saveProductIntoCustomPriceRequest } from "api/corporationSetting";
import { editCustomPriceProductRequest } from "api/corporationSetting";

class CustomPriceEdit extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			customPrice: null,
			isCustomPriceModalOpen: false,
			isCreateCustomPriceModal: true,
			selectedCustomPriceProduct: null,
			deleteCustomPriceProductId: null,
			customPriceModalError: ''
		}
	}

	async componentDidMount() {
		this.getCustomPrice()
	}

	getCustomPrice = async () => {
		const params = new URLSearchParams(this.props.location.search);
		const id = params.get('id');

		if (id) {
			const customPrice = await getCorporationCustomPriceByIdRequest(id);
			this.setState({ customPrice })
		} else {
			this.goTo('/custom-prices')
		}
	}

	customPriceModalHandler = (isOpen, isCreateCustomPriceModal = false, selectedCustomPriceProduct = null) => {
		this.setState({
			isCustomPriceModalOpen: isOpen,
			isCreateCustomPriceModal,
			selectedCustomPriceProduct: selectedCustomPriceProduct,
		})
	}

	openCreateConfirmationModal = () => {
		this.customPriceModalHandler(true, true)
	}
	
	openEditConfirmationModal = (customPriceProduct) => {
		this.customPriceModalHandler(true, false, customPriceProduct)
	}

	onSaveNewCustomPrice = async (customPriceProduct) => {
		try {
			const { customPrice } = this.state

			await saveProductIntoCustomPriceRequest(customPrice._id, customPriceProduct)
			this.customPriceModalHandler(false, true, null)
			this.getCustomPrice()
		} catch (e) {
			this.setState({
				customPriceModalError: e.message
			})
		}
	} 
	
	onEditCustomPrice = async (customPriceProduct) => {
		try {
			const { customPrice } = this.state

			await editCustomPriceProductRequest(customPrice._id, customPriceProduct)
			this.customPriceModalHandler(false, true, null)
			this.getCustomPrice()
		} catch (e) {
			this.setState({
				customPriceModalError: e.message
			})
		}
	}

	deleteCustomPriceProduct = async () => {
		try {
			const { deleteCustomPriceProductId, customPrice} = this.state

			await deleteProductFromCustomPriceRequest(customPrice._id, deleteCustomPriceProductId)
			this.getCustomPrice()
		} catch (e) {}
	}

	openDeleteConfirmationModal = (deleteId) => {
		this.setState({
			deleteCustomPriceProductId: deleteId
		});
		this.deleteConfirmationModal.toggle();
    }

	goTo = path => this.props.history.push(path);

	render() {

		const {
			customPrice,
			isCustomPriceModalOpen,
			isCreateCustomPriceModal,
			selectedCustomPriceProduct,
			customPriceModalError
		} = this.state;

		const {
            clientCompany,
            products,
			createdAt,
			updatedAt
		} = customPrice || {};

		return (
			<>
				<div className="content">
					<Row>
						<Col md="12">
							<Card>
								<CardHeader className="mb-5">
									<CardTitle tag="h3">
										Edit Custom Price
                  					</CardTitle>
									<Button
										className="float-right"
										color="default"
										onClick={() => this.goTo('/custom-prices')}
									>
										Back
                  					</Button>
								</CardHeader>
								<CardBody>
									{!customPrice
										? <div>Loading...</div>
										: <>
											<div className="typography-line">
												<h4>
													<span>Client Company</span>
													{clientCompany.name}
												</h4>
											</div>
											<div className="typography-line">
												<h4>
													<span>Created At</span>
													{createdAt ? moment(createdAt).format("YYYY-MM-DD HH:mm:ss") : "N/A"}
												</h4>
											</div>
											<div className="typography-line">
												<h4>
													<span>Updated At</span>
													{updatedAt ? moment(updatedAt).format("YYYY-MM-DD HH:mm:ss") : "N/A"}
												</h4>
											</div>
										</>
									}
								</CardBody>
							</Card>
							{
								customPrice &&
								<Card>
								<CardBody>
									<CardHeader className='d-flex align-items-center justify-content-between'>
										<CardTitle tag="h3">
											Custom Price Products
										</CardTitle>
										<Button className="pull-right mb-3" color="primary" onClick={this.openCreateConfirmationModal}>Add Product</Button>
										{/* <Input placeholder="Search Users..." value={search} type="text" onChange={this.onChangeSearch}></Input> */}
									</CardHeader>
									<Table responsive>
										<thead className="text-primary">
											<tr>
												<th>Image</th>
												<th>Name</th>
												<th>Custom Price Price</th>
												<th>Actions</th>
											</tr>
										</thead>
										<tbody>
											{products && products.length
											? products.map((product) => {
												return (
													<tr key={product._id}>
														<td>{product.product.images && product.product.images.length > 0
																? <a href={product.product.images[0]} target="_blank" rel="noreferrer"><img alt='Product' className="product-preview" src={product.product.images[0]} /></a> 
																: <img alt='Product placeholder' className="product-preview" src={productPlaceholder} />
															}
														</td>
														<td>{product.product.name || "N/A"}</td>
														<td>$ {formatPrice(product.price )|| "N/A"}</td>
														<td>
															<Button
																className="btn-link btn-icon"
																color="info"
																id={`tooltip-edit-${product._id}`}
																size="sm"
																onClick={() => this.openEditConfirmationModal(product)}
															>
																<i className="tim-icons icon-pencil" />
															</Button>
															<UncontrolledTooltip
																delay={0}
																target={`tooltip-edit-${product._id}`}
															>
																Edit
															</UncontrolledTooltip>
															<Button
																className="btn-link btn-icon"
																color="danger"
																id={`tooltip-remove-${product._id}`}
																size="sm"
																onClick={() => this.openDeleteConfirmationModal(product.product._id)}
															>
																<i className="tim-icons icon-simple-remove" />
															</Button>
															<UncontrolledTooltip
																delay={0}
																target={`tooltip-remove-${product._id}`}
															>
																Remove
															</UncontrolledTooltip>
														</td>
													</tr>
												)
											})
											:   <tr>
													<td colSpan="7">There's no product in the database</td>
												</tr>
											}
										</tbody>
									</Table> 
								</CardBody>
							</Card>
							}
						</Col>
					</Row>
					<CustomPriceModal
						rel={el => this.customPriceModal = el}
						isLoading={false}
						onSave={isCreateCustomPriceModal ? this.onSaveNewCustomPrice : this.onEditCustomPrice}
						isOpen={isCustomPriceModalOpen}
						onClose={() => this.customPriceModalHandler(false)}
						isCreateNewCustomPrice={isCreateCustomPriceModal}
						customPriceProduct={selectedCustomPriceProduct}
						error={customPriceModalError}
					/>
					<ConfirmationModal
                        ref={el => this.deleteConfirmationModal = el}
                        title="Delete Custom Price Product?"
                        buttons={[
                            { title: 'Cancel', className: 'btn-primary-override btn btn-primary' },
                            { title: 'Delete', className: 'btn-danger-override btn btn-danger', onClick: this.deleteCustomPriceProduct }
                        ]}
						>
                        Are you sure you want to delete this product from this custom price?
                    </ConfirmationModal>
				</div>
			</>
		);
	}
}

export default CustomPriceEdit;
