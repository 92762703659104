import React from "react";
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  UncontrolledAlert,
  Label,
} from "reactstrap";
import { sendResetPasswordEmail } from "api/auth";

class SendEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      apiError: "",
      isSuccess: false,
    };
  }

  componentDidMount() {
    document.body.classList.toggle("login-page");
  }

  componentWillUnmount() {
    document.body.classList.toggle("login-page");
  }

  onSendEmail = async () => {
    const emailRegex = /\S+@\S+\.\S+/;
    const { email } = this.state;

    try {
      if (!emailRegex.test(email)) {
        throw new Error("Invalid email");
      }

      await sendResetPasswordEmail(email);
      this.setState({ isSuccess: true, apiError: "" });
    } catch (error) {
      this.setState({
        apiError: error.message,
      });
    }
  };

  render() {
    const { email, isSuccess, apiError } = this.state;

    return (
      <div className="content">
        <Container>
          <Col className="ml-auto mr-auto" lg="4" md="6">
            <Form className="form">
              <Card className="card-login card-white">
                <CardHeader>
                  <img alt="..." src={require("assets/img/card-primary.png")} />
                  <CardTitle tag="h6">Email</CardTitle>
                </CardHeader>
                <CardBody>
                  <Label>
                    Here you need to fill in the field below with your login
                    email
                  </Label>

                  <InputGroup
                    className={classnames({
                      "input-group-focus": this.state.emailFocus,
                    })}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="tim-icons icon-email-85" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Email"
                      type="text"
                      name="email"
                      onFocus={(e) => this.setState({ emailFocus: true })}
                      onBlur={(e) => this.setState({ emailFocus: false })}
                      value={email}
                      onChange={(e) => this.setState({ email: e.target.value })}
                    />
                  </InputGroup>
                </CardBody>
                <CardFooter>
                  {apiError && (
                    <UncontrolledAlert color="primary" fade={false}>
                      <span>{apiError}</span>
                    </UncontrolledAlert>
                  )}
                  {isSuccess && (
                    <UncontrolledAlert fade={false}>
                      <span>
                        The email was sent successfully!
                        <br />
                        Please check your email to see the next steps
                      </span>
                    </UncontrolledAlert>
                  )}
                  <Button
                    loading={true}
                    block
                    className="mb-3"
                    color="primary"
                    onClick={() => this.onSendEmail()}
                    size="lg"
                    disabled={isSuccess}
                  >
                    Continue
                  </Button>
                </CardFooter>
              </Card>
            </Form>
          </Col>
        </Container>
      </div>
    );
  }
}

export default SendEmail;
