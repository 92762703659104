import { apiRequest} from './index'

export const getCompaniesRequest = (page, limit, search, role, corporation) => {
  return apiRequest(
    `/corporations/companies?page=${page}&limit=${limit}&search=${search}&role=${role}&corporation=${corporation}`,
    {
      method: 'GET'
    },
    true
  )
}

export const getCompanyRequest = (id) => {
  return apiRequest(
    `/corporations/companies/${id}`,
    {
      method: 'GET'
    },
    true
  )
}

export const updateCompanyRequest = (company) => {
  return apiRequest(
    `/corporations/companies/${company._id}`,
    {
			method: 'PUT',
			body: JSON.stringify(company)
    },
    true
  )
}

export const createCompanyRequest = (company) => {
  return apiRequest(
    `/corporations/companies`,
    {
			method: 'POST',
			body: JSON.stringify(company)
    },
    true
  )
}

export const deleteCompanyRequest = (companyId) => {
  return apiRequest(
    `/corporations/companies/${companyId}`,
    {
			method: 'DELETE'
    },
    true
  )
}

export const getCompanyStatsRequest = () => {
  return apiRequest(
    `/corporations/company-stats`,
    {
      method: 'GET'
    },
    true
  )
}

export const getCompanyProductsRequest = (companyId = '', filter = {}) => {
  const {
    clientCompanyId = '',
    search = ''
  } = filter || {}

  return apiRequest(
    `/companies/${companyId}/products?clientCompanyId=${clientCompanyId}&search=${search}`,
    {
      method: 'GET'
    },
    true
  )
}

export const deleteCompanyProductRequest = (companyId, productId) => {
  return apiRequest(
    `/me/companies/${companyId}/product/${productId}/delete`,
    {
			method: 'DELETE'
    },
    true
  )
}

export const editCompanyProductRequest = (companyId, productId, body) => {
  return apiRequest(
    `/me/companies/${companyId}/product/${productId}/edit`,
    {
			method: 'PUT',
      body: JSON.stringify(body)
    },
    true
  )
}

export const saveProductIntoCompanyRequest = (body) => {
  return apiRequest(
    `/me/companies/save-product`,
    {
			method: 'POST',
      body: JSON.stringify(body)
    },
    true
  )
}
