import React from "react";
// reactstrap components
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	CardTitle,
	Row,
	Col,
	UncontrolledTooltip,
	Table,
} from "reactstrap";
import moment from 'moment';
// Helpers
import { formatRole } from 'helpers/strings'
import { formatStateName } from 'helpers/state'
// Requests
import { deleteCompanyProductRequest, editCompanyProductRequest, getCompanyRequest, saveProductIntoCompanyRequest } from '../api/company';
import { formatPrice } from "helpers/pricing";
import productPlaceholder from 'assets/img/product-placeholder.jpg';
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import ProductStoreFormModal from "components/Modals/ProductStoreFormModal";


class StoreDetails extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			store: null,
			deleteProductId: null,
            editProduct: null,
            isCreateModal: false,
            formModalIsOpen: false,
            formModalError: '',
            formModalIsLoading: false
		}
	}

	async componentDidMount() {
        console.log('aquiiii')
		this.getStore()
	}
	
	getStore = async () => {
		const params = new URLSearchParams(this.props.location.search);
		const id = params.get('id');

		if (id) {
			const store = await getCompanyRequest(id);
			this.setState({ store })
		} else {
			this.goTo('/stores')
		}
	}

	goTo = path => this.props.history.push(path);

	deleteCompanyProduct = async () => {
        const { deleteProductId, store } = this.state
        await deleteCompanyProductRequest(store?._id, deleteProductId)
        this.getStore()
    }

    editCompanyProduct = async (product) => {
        try {
            const { store } = this.state
            this.setState({ formModalIsLoading: true, formModalError: '' })
            await editCompanyProductRequest(store?._id, product.product._id, product)
            this.setState({ formModalIsLoading: false })
            this.getStore()
            this.toggleCompanyProductModal(false)
        } catch (e) {
            this.setState({
                formModalError: e.message
            })
        }
    }

    createCompanyProduct = async (product) => {
        try {
            const { store } = this.state
            this.setState({ formModalIsLoading: true, formModalError: '' })
            await saveProductIntoCompanyRequest({
                product,
                companyId: store._id
            })
            this.setState({ formModalIsLoading: false })

            this.getStore()
            this.toggleCompanyProductModal(false)
        } catch (e) {
            this.setState({
                formModalError: e.message
            })
        }
    }

    openDeleteConfirmationModal = (deleteId) => {
		this.setState({
			deleteProductId: deleteId
		});
		this.deleteConfirmationModal.toggle();
    }

    toggleCompanyProductModal (isOpen) {
        this.setState({
            formModalIsOpen: isOpen
        })
    }

    openEditConfirmationModal = (product) => {
		this.setState({
			editProduct: product,
            isCreateModal: false,
		});
        this.toggleCompanyProductModal(true)
    }

    openCreateConfirmationModal = () => {
		this.setState({
            isCreateModal: true,
            editProduct: null
		});
        this.toggleCompanyProductModal(true)
    }

	render() {

        console.log('renderizouuuu')

		const {
			store = {},
            isCreateModal,
            formModalIsOpen,
            formModalError,
            formModalIsLoading
		} = this.state;

		const {
			_id,
            name,
            role,
            owner,
            alternateEmail,
            address,
			createdAt,
			updatedAt,
			products
		} = store || {};

		return (
			<>
				<div className="content">
					<Row>
						<Col md="12">
							<Card>
								<CardHeader className="mb-5">
									<CardTitle tag="h3">
										Store Details
                  					</CardTitle>
									<Button
										className="float-right"
										color="default"
										onClick={() => this.goTo('/stores')}
									>
										Back
                  					</Button>
								</CardHeader>
								<CardBody>
									{!store
										? <div>Loading...</div>
										: <>
											<div className="typography-line">
												<h4>
													<span>ID</span>
													{_id}
												</h4>
											</div>
                                            <div className="typography-line">
												<h4>
													<span>Name</span>
													{name}
												</h4>
											</div>
                                            <div className="typography-line">
                                                <h4>
                                                    <span>Role</span>
                                                    {formatRole(role)}
                                                </h4>
                                            </div>
                                            <div className="typography-line">
                                                <h4>
                                                    <span>Owner Email</span>
                                                    {owner?.email || 'N/A'}
                                                </h4>
                                            </div>
                                            <div className="typography-line">
                                                <h4>
                                                    <span>Alternate Email</span>
                                                    {alternateEmail || 'N/A'}
                                                </h4>
                                            </div>
                                            {role === "seller" &&
                                                <Card className='detail-card'>
                                                    <CardHeader>
                                                        <h3>Address</h3>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <div className="typography-line">
                                                            <h4>
                                                                <span>State</span>
                                                                {formatStateName(address?.state) || 'N/A'}
                                                            </h4>
                                                        </div>
                                                        <div className="typography-line">
                                                            <h4>
                                                                <span>City</span>
                                                                {address?.city || 'N/A'}
                                                            </h4>
                                                        </div>
                                                        <div className="typography-line">
                                                            <h4>
                                                                <span>Street Address</span>
                                                                {address?.streetAddress || 'N/A'}
                                                            </h4>
                                                        </div>
                                                        <div className="typography-line">
                                                            <h4>
                                                                <span>Notes</span>
                                                                {address?.notes || 'N/A'}
                                                            </h4>
                                                        </div>
                                                        <div className="typography-line">
                                                            <h4>
                                                                <span>ZIP Code</span>
                                                                {address?.zipCode || 'N/A'}
                                                            </h4>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            }
											<div className="typography-line">
												<h4>
													<span>Created At</span>
													{createdAt ? moment(createdAt).format("YYYY-MM-DD HH:mm:ss") : "N/A"}
												</h4>
											</div>
											<div className="typography-line">
												<h4>
													<span>Updated At</span>
													{updatedAt ? moment(updatedAt).format("YYYY-MM-DD HH:mm:ss") : "N/A"}
												</h4>
											</div>
										</>
									}
								</CardBody>
							</Card>
						</Col>
						{store?.role === 'seller' ?
                            <Col>
                                <Card>
                                    <CardBody>
                                        <CardHeader className='d-flex align-items-center justify-content-between'>
                                            <CardTitle tag="h3">
                                                Store Products
                                            </CardTitle>
                                            <Button className="pull-right mb-3" color="primary" onClick={this.openCreateConfirmationModal}>Add Product</Button>
                                            {/* <Input placeholder="Search Users..." value={search} type="text" onChange={this.onChangeSearch}></Input> */}
                                        </CardHeader>
                                        <Table responsive>
                                            <thead className="text-primary">
                                                <tr>
                                                    <th>Image</th>
                                                    <th>Name</th>
                                                    <th>Price</th>
													<th>Has Discount</th>
                                                    <th>Original price</th>
                                                    <th>Quantity</th>
                                                    <th>Store SKU</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {products && products.length
                                                ? products.map((product) => {
                                                    return (
                                                        <tr key={product._id}>
                                                            <td>{product.product.images && product.product.images.length > 0
                                                                    ? <a href={product.product.images[0]} target="_blank" rel="noreferrer"><img className="product-preview" src={product.product.images[0]} /></a> 
                                                                    : <img className="product-preview" src={productPlaceholder} />
                                                                }
                                                            </td>
                                                            <td>{product.product.name || "N/A"}</td>
                                                            <td>$ {formatPrice(product.price )|| "N/A"}</td>
															<td>{product.hasDiscount ? 'Yes' : 'No'}</td>
                                                            <td>$ {formatPrice(product.comparePrice )|| "N/A"}</td>
                                                            <td>{product.quantity}</td>
                                                            <td>{product.storeSku}</td>
                                                            <td>
                                                                <Button
                                                                    className="btn-link btn-icon"
                                                                    color="info"
                                                                    id={`tooltip-edit-${product._id}`}
                                                                    size="sm"
                                                                    onClick={() => this.openEditConfirmationModal(product)}
                                                                >
                                                                    <i className="tim-icons icon-pencil" />
                                                                </Button>
                                                                <UncontrolledTooltip
                                                                    delay={0}
                                                                    target={`tooltip-edit-${product._id}`}
                                                                >
                                                                    Edit
                                                                </UncontrolledTooltip>
                                                                <Button
                                                                    className="btn-link btn-icon"
                                                                    color="danger"
                                                                    id={`tooltip-remove-${product._id}`}
                                                                    size="sm"
                                                                    onClick={() => this.openDeleteConfirmationModal(product.product._id)}
                                                                >
                                                                    <i className="tim-icons icon-simple-remove" />
                                                                </Button>
                                                                <UncontrolledTooltip
                                                                    delay={0}
                                                                    target={`tooltip-remove-${product._id}`}
                                                                >
                                                                    Remove
                                                                </UncontrolledTooltip>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                                :   <tr>
                                                        <td colSpan="7">There's no product in the store's inventory</td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </Table> 
                                    </CardBody>
                                </Card>
                            </Col>
                            : null
                        }
					</Row>
					<ConfirmationModal
                        ref={el => this.deleteConfirmationModal = el}
                        title="Remove Company Product?"
                        buttons={[
                            { title: 'Cancel', className: 'btn-primary-override btn btn-primary' },
                            { title: 'Remove', className: 'btn-danger-override btn btn-danger', onClick: this.deleteCompanyProduct }
                        ]}
                    >
                        Are you sure you want to remove this product from the store's inventory?
                    </ConfirmationModal>
                    <ProductStoreFormModal 
                        loading={false}
                        isCreateModal={isCreateModal}
                        editProduct={this.state.editProduct}
                        onSave={isCreateModal ? this.createCompanyProduct : this.editCompanyProduct}
                        closeModal={() => this.toggleCompanyProductModal(false)}
                        isOpen={formModalIsOpen}
                        error={formModalError}
                        isLoading={formModalIsLoading}
                    />

				</div>
			</>
		);
	}
}

export default StoreDetails;
