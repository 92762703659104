import { apiRequest} from './index'

export const getProductsRequest = (page, limit, search, company='', status='') => {
  return apiRequest(
    `/corporations/products?page=${page}&limit=${limit}&term=${search}&sellerCompany=${company}&status=${status}`,
    {
      method: 'GET'
    },
    true
  )
}

export const getProductRequest = (id) => {
  return apiRequest(
    `/corporations/products/${id}`,
    {
      method: 'GET'
    },
    true
  )
}

export const getProductsStatsRequest = () => {
  return apiRequest(
    `/corporations/product-stats`,
    {
      method: 'GET'
    },
    true
  )
}

