import React from "react";
// reactstrap components
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	CardTitle,
	Row,
	Col,
	FormGroup,
	Label,
	Input
} from "reactstrap";
// Requests
import { createCorporationSettingRequest } from "api/corporationSetting";

class TierCreate extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			corporationSettings: true,
			corporationId: null,
			tierNumber: null,
			error: null
		}
	}

	async componentDidMount() {
		const params = new URLSearchParams(this.props.location.search);
		const corporationId = params.get('corporationId');
		const tierNumber = params.get('tierNumber');
		
		this.setState({
			corporationId,
			tierNumber
		})
	}

	goTo = path => this.props.history.push(path);

	onChangeField = (e) => {
		const { target } = e;
		const { name, value } = target;
		this.setState(prevState => ({
			corporationSettings: {
				...prevState.corporationSettings,
				[name]: value,
			},
		}));
	}

    save = async () => {
		const {
			corporationSettings,
			corporationId,
			tierNumber
		} = this.state;

		const {
            discountPercent,
            description
		} = corporationSettings || {};

		const data = {
			corporationId,
			tierNumber,
			discountPercent,
            description
		};

		try {
			await createCorporationSettingRequest(data);
			this.goTo('/settings');
		} catch (err) {
			console.log(err, "error");
			this.setState({
				error: err.message || 'There was an error saving tier'
			})
		}

    }

	render() {

		const {
			corporationSettings = {},
			error = null
		} = this.state;

        const {
            discountPercent,
            description
		} = corporationSettings || {};

		const {
			tierNumber
		} = this.state;

		return (
			<>
				<div className="content">
					<Row>
						<Col md="12">
							<Card>
								<CardHeader className="mb-5">
									<CardTitle tag="h3">
										Create Tier
                  					</CardTitle>
									<Button
										className="float-right"
										color="default"
										onClick={() => this.goTo('/settings')}
									>
										Back
 					                </Button>
								</CardHeader>
								<CardBody>
									{!corporationSettings
										? <div>Loading...</div>
										: <>
											<FormGroup>
												<Label>Tier Number</Label>
												<Input
													type="text"
													name="tierNumber"
													value={tierNumber || ''}
													disabled
												/>
											</FormGroup>
											<FormGroup>
												<Label>Discount Percent</Label>
												<Input
													type="text"
													name="discountPercent"
													value={discountPercent || ''}
													onChange={this.onChangeField}
												/>
											</FormGroup>
                                            <FormGroup>
												<Label>Description</Label>
												<Input
													type="text"
													name="description"
													value={description || ''}
													onChange={this.onChangeField}
												/>
											</FormGroup>

										</>
									}
									{error ? <div className="alert alert-danger mt-3">
											<span>{error}</span>
										</div> : null
									}
                                    <Button color="primary" block={false} className="mb-4" onClick={this.save}>
                                        Create Tier
                                    </Button>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</div>
			</>
		);
	}
}

export default TierCreate;

