export const formatTransactionStatus = (status) => {
	let formattedStatus = "";

    switch(status) {
        case 'pending':
            formattedStatus = "Pending";
            break;
        case 'completed':
            formattedStatus = "Completed";
            break;
        case 'confirmed':
            formattedStatus = "Confirmed";
            break;
        case 'canceled':
            formattedStatus = "Canceled";
            break;
        case 'created':
            formattedStatus = "Created";
            break;
        default:
            formattedStatus = status;
    }

	return formattedStatus;
}

export const formatProductStatus = (status) => {
	let formattedStatus = "";

    switch(status) {
        case 'created':
            formattedStatus = "Created";
            break;
        case 'available':
            formattedStatus = "Available";
            break;
        case 'withdrawn':
            formattedStatus = "Withdrawn";
            break;
        case 'soldOut':
            formattedStatus = "Sold Out";
            break;
        default:
            formattedStatus = status;
    }

	return formattedStatus;
}

export const formatOrderStatus = (status) => {
	let formattedStatus = "";

    switch(status) {
        case 'created':
            formattedStatus = "Created";
            break;
        case 'driverAssigned':
            formattedStatus = "Driver Assigned";
            break;
        case 'arrivedForPickup':
            formattedStatus = "Arrived For Pickup";
            break;
        case 'leftForDelivery':
            formattedStatus = "Left for Delivery";
            break;
        case 'completed':
            formattedStatus = "Completed";
            break;
        case 'canceled':
            formattedStatus = "Canceled";
            break;
        default:
            formattedStatus = status;
    }

	return formattedStatus;
}

export const formatTransferStatus = (status) => {
	let formattedStatus = "";

    switch(status) {
        case 'created':
            formattedStatus = "Created";
            break;
        case 'driverAssigned':
            formattedStatus = "Driver Assigned";
            break;
        case 'arrivedForPickup':
            formattedStatus = "Arrived For Pickup";
            break;
        case 'leftForDelivery':
            formattedStatus = "Left for Delivery";
            break;
        case 'completed':
            formattedStatus = "Completed";
            break;
        case 'canceled':
            formattedStatus = "Canceled";
            break;
        default:
            formattedStatus = status;
    }

	return formattedStatus;
}


export const formatRunStatus = (status) => {
	let formattedStatus = "";

    switch(status) {
        case 'created':
            formattedStatus = "Created";
            break;
        case 'driverAssigned':
            formattedStatus = "Driver Assigned";
            break;
        case 'arrivedForPickup':
            formattedStatus = "Arrived for Pickup";
            break;
        case 'leftForDelivery':
            formattedStatus = "Left for Delivery";
            break;
        case 'arrivedForDelivery':
            formattedStatus = "Arrived for Delivery";
            break;
        case 'completed':
            formattedStatus = "Completed";
            break;
        default:
            formattedStatus = status;
    }

	return formattedStatus;
}

