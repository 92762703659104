export const formatRole = (role) => {
	let formattedRole = "";

    switch(role) {
        case 'client':
            formattedRole = "Client";
            break;
        case 'seller':
            formattedRole = "Seller";
            break;
        case 'driver':
            formattedRole = "Driver";
            break;
        default:
            formattedRole = role;
    }

	return formattedRole;
}

export const formatArea = (area) => {
    if (!area) return "";

    const [country, state, city] = area.split('-')

    return `${city}, ${state}`
}