import { apiRequest} from './index'

export const getCorporationsRequest = (page, limit, search, role) => {
  return apiRequest(
    `/admin/corporations?page=${page}&limit=${limit}&search=${search}&role=${role}`,
    {
      method: 'GET'
    },
    true
  )
}

export const getCorporationRequest = (id) => {
  return apiRequest(
    `/admin/corporations/${id}`,
    {
      method: 'GET'
    },
    true
  )
}

export const createCompanyRequest = (data) => {
  return apiRequest(
    `/corporations/company/create`,
    {
			method: 'POST',
			body: JSON.stringify(data)
    },
    true
  )
}

export const getCorporationCustomPricesRequest = (page, limit, search, corporation) => {
  return apiRequest(
    `/corporations/custom-prices/corporation/${corporation}?page=${page}&limit=${limit}&search=${search}`,
    {
      method: 'GET'
    },
    true
  )
}

export const getCorporationCustomPriceByIdRequest = (id) => {
  return apiRequest(
    `/corporations/custom-prices/${id}`,
    {
      method: 'GET'
    },
    true
  )
}

export const deleteCorporationCustomPricesRequest = (id) => {
  return apiRequest(
    `/corporations/custom-prices/${id}`,
    {
			method: 'DELETE'
    },
    true
  )
}