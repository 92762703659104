import React from "react";
// reactstrap components
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	CardTitle,
	Row,
	Col,
	FormGroup,
	Input,
	Label,
	Table,
	UncontrolledTooltip
} from "reactstrap";
import Async from 'react-select/async';
// Helpers
import { calcStartEndPage } from "helpers/pagination";
import { calculateDeliveryFee } from "helpers/fees"
import { formatPrice } from "helpers/pricing"
// Requests
import { createTransferRequest } from "api/transfer";
import { getProductsRequest } from "api/product";
import { getCompaniesRequest, getCompanyProductsRequest } from "api/company";
// Assets
import productPlaceholder from 'assets/img/product-placeholder.jpg';

class TransfersCreate extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			corporationId: null,
			originCompany: null,
            destinationCompany: null,
			error: null,
			products: [],
			addedProducts: [],
			selectedProduct: null,
			selectedQuantity: '',
			page: 0,
			limit: 25,
			search: "",
			isAddingProduct: false,
			isSaving: false,
			deliveryFee: null,
			miscellaneousProducts: null
		}
	}

	async componentDidMount() {
		const params = new URLSearchParams(this.props.location.search);
		const corporationId = params.get('corporationId');
		
		this.setState({
			corporationId
		})
	}

	loadCompanies = async (text, callback) => {
		if(text) {
			const companiesResponse = await getCompaniesRequest(0, 10, text, "", this.state.corporationId)
			const companies = companiesResponse.companies.map(company => {
				return {
					data: company,
					label: company.name,
					value: company._id
				}
			});
			callback(companies);
		}
	}

	loadProducts = async (text, callback) => {
		if(text) {
			const productsResponse = await getCompanyProductsRequest(this.state.originCompany.value, {search: text});
			const products = productsResponse.products.map(productObj => {
				return {
					data: productObj.product,
					label: 
					<div>
						<img 
							className="mr-3" 
							height="30px" 
							width="30px" 
							src={productObj.product.images[0] ? productObj.product.images[0] : productPlaceholder}>
						</img>
							{productObj.product.name}
					</div>,
					value: productObj.product._id
				}
			});
			callback(products);
		}
	}


	onSelectChange = (key) => (value) => {
		this.setState({
			[key]: value
		}, () => {
			if (this.state.originCompany && this.state.destinationCompany) {
				const pickupAddress = this.state.originCompany.data.address;
				const deliveryAddress = this.state.destinationCompany.data.address;
				const deliveryFee = calculateDeliveryFee(pickupAddress, deliveryAddress)
				this.setState({deliveryFee})
			}
		});
	}

	onFormInput = (e) => {
		const { name, value } = e.target;

		this.setState({
		    [name]: value
		})
	}

	onChangeFilter = (event) => {
		const { name, value } = event.target;
		this.setState({
		  [name]: value,
		  page: 0,
		});
		if (this.timeout) {
		  clearTimeout(this.timeout);
		}
		this.timeout = setTimeout(() => {
		  this.fetchProducts();
		}, 300);
	};

	goTo = path => this.props.history.push(path);

	addProduct = () => {

		this.setState({
			isAddingProduct: true
		})

		const {
			selectedProduct,
			selectedQuantity,
			addedProducts
		} = this.state;

		const obj = {
			product: selectedProduct.data,
			quantity: selectedQuantity
		};

		addedProducts.push(obj);

		this.setState({
			addedProducts,
			isAddingProduct: false,
			selectedProduct: null,
			selectedQuantity: ''
		})
	}

	save = async () => {

		this.setState({
			error: null,
			isSaving: true
		})

		const {
			corporationId,
			originCompany,
            destinationCompany,
			addedProducts,
			deliveryFee,
			total,
			miscellaneousProducts
		} = this.state;

		const products = addedProducts.map((entry) => {
			return {
				product: entry.product._id,
				quantity: entry.quantity
			}
		})

		const data = {
			products,
			miscellaneousProducts,
			corporationId,
			originCompany: originCompany.value,
            destinationCompany: destinationCompany.value,
			deliveryFee,
			total: deliveryFee
		};

		try {

			await createTransferRequest(data);

			this.setState({
				error: null,
				isSaving: false
			})

			this.goTo('/transfers');

		} catch (err) {

			this.setState({
				error: err.message || 'There was an error creating Transfer',
				isSaving: false
			})
		}
	}

	goToPage = async (page) => {
		const { limit, pages, search } = this.state;
		const productsResponse = await getProductsRequest(page, limit, search);
		const products = productsResponse.products;
		const { start_page, end_page } = calcStartEndPage(page, pages);
	
		this.setState({
		  products,
		  current_page: page,
		  start_page: start_page,
		  end_page: end_page,
		});
	};

	render() {

		const {
			originCompany,
            destinationCompany,
			isAddingProduct,
			addedProducts,
			selectedProduct,
			selectedQuantity,
			error,
			isSaving,
			deliveryFee,
			miscellaneousProducts
		} = this.state;

		const isButtonDisabled = !originCompany || !destinationCompany || ((!addedProducts || addedProducts && addedProducts.length === 0) && !miscellaneousProducts) || !deliveryFee || isSaving

		return (
			<>
				<div className="content">
					<Row>
						<Col md="12">
							<Card>
								<CardHeader className="mb-5">
									<CardTitle tag="h3">
										Create Transfer
                  					</CardTitle>
									<Button
										className="float-right"
										color="default"
										onClick={() => this.goTo('/transfers')}
									>
										Back
 					        		</Button>
								</CardHeader>
								<CardBody>
									<>
										<h4 className="mb-1">1. Select Origin Store</h4>
										<p className="mb-3">Select the store which you want to send products from</p>
										<FormGroup>
											<Async
												value={originCompany}
												name="originCompany"
												options={[]}
												loadOptions={this.loadCompanies}
												className="basic-multi-select"
												classNamePrefix="select"
												onChange={this.onSelectChange('originCompany')}
												isClearable
												placeholder="Start typing to load options..."
											/>
										</FormGroup>

                                        <h4 className="mb-1 mt-5">2. Select Destination Store</h4>
										<p className="mb-3">Select the store which you want to send products to</p>
										<FormGroup>
											<Async
												value={destinationCompany}
												name="destinationCompany"
												options={[]}
												loadOptions={this.loadCompanies}
												className="basic-multi-select"
												classNamePrefix="select"
												onChange={this.onSelectChange('destinationCompany')}
												isClearable
												placeholder="Start typing to load options..."
											/>
										</FormGroup>

										{originCompany && destinationCompany &&
											<>
												<h4 className="mb-3 mt-5">3. Review the delivery fee below for a transfer between these two addresses</h4>
												<div className="typography-line">
													<h4>
														<span>Delivery Fee</span>
														{deliveryFee ? `$ ${formatPrice(deliveryFee)}` : "N/A"}
													</h4>
												</div>
											</>
										}

										{deliveryFee &&
											<>
												<h4 className="my-3">4. Select App Products to be transferred</h4>

												<Card className='detail-card'>
													<CardHeader>
														<h3>Product Info</h3>
													</CardHeader>

													<CardBody>
														<FormGroup>
															<Label>Select Product</Label>
															<Async
																value={selectedProduct}
																name="selectedProduct"
																options={[]}
																loadOptions={this.loadProducts}
																className="basic-multi-select"
																classNamePrefix="select"
																onChange={this.onSelectChange('selectedProduct')}
																isClearable
																placeholder="Start typing to load products..."
															/>
														</FormGroup>

														<FormGroup>
															<Label>Select Quantity</Label>
															<Input
																type="text"
																name="selectedQuantity"
																value={selectedQuantity}
																onChange={this.onFormInput}
															/>
														</FormGroup> 

														<Button
															onClick={this.addProduct}
															disabled={isAddingProduct}
															className="d-flex justify-content-around"
															color="success"
															size="sm"
														>
															Add Product 
														</Button>

													</CardBody>
												</Card>
													<h4 className="my-3">5. Describe non-App Products to be transferred</h4>
													<Input
														type="text"
														name="miscellaneousProducts"
														value={miscellaneousProducts}
														onChange={this.onFormInput}
													/>
											</>

										}

										{((addedProducts && addedProducts.length) || miscellaneousProducts)
											? <>
												<h4 className="my-3">6. Review list of products and their respective quantities</h4>
												<Table responsive>
													<thead className="text-primary">
														<tr>
															<th>Image</th>
															<th>Name</th>
															<th>Quantity</th>
															<th className="text-right">Actions</th>
														</tr>
													</thead>
													<tbody>
														{addedProducts.map((addedProductObj, index) => {
															return (
																<tr key={addedProductObj.product._id}>
																	<td>
																		{addedProductObj.product.images && addedProductObj.product.images.length > 0
																			? <img className="product-preview" src={addedProductObj.product.images[0]} />
																			: <img className="product-preview" src={productPlaceholder} />
																		}
																	</td>
																	<td>{addedProductObj.product.name}</td>
																	<td>{addedProductObj.quantity}</td>
																	<td className="text-right">
																		<Button
																			className="btn-link btn-icon"
																			color="danger"
																			id={`tooltip-delete-${addedProductObj.product._id}`}
																			size="sm"
																			onClick={() => this.onRemoveFromAdded(index)}
																		>
																			<i className="tim-icons icon-simple-remove" />
																		</Button>
																		<UncontrolledTooltip
																			delay={0}
																			target={`tooltip-delete-${addedProductObj.product._id}`}
																		>
																			Remove
																		</UncontrolledTooltip>
																	</td>
																</tr>
															)
														})}
														{miscellaneousProducts && (
															<tr>
																<td>Extras:</td>
																<td colSpan={3}>{miscellaneousProducts}</td>
															</tr>
														)}
													</tbody>
												</Table>

												<h4 className="my-3">6. If everything is correct, click the button below to create transfer</h4>

												{error ? <div className="alert alert-danger mt-3">
														<span>{error}</span>
													</div> : null
												}
												<Button 
													color="primary" 
													block={false} 
													className="mb-4 mt-4" 
													onClick={this.save}
													disabled={isButtonDisabled}
												>
													Create Transfer
												</Button>
											</>
											: null
										}
									</>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</div>
			</>
		);
	}
}

export default TransfersCreate;

