import React from "react";
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  UncontrolledAlert,
} from "reactstrap";
import { resetPassword } from "api/auth";

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      repeatPassword: "",
      apiError: "",
      passFocus: false,
      repeatPasswordFocus: false,
      isSuccess: false,
    };
  }

  componentDidMount() {
    document.body.classList.toggle("login-page");
  }

  componentWillUnmount() {
    document.body.classList.toggle("login-page");
  }

  handleResetPasswordSuccess = () => {
    this.setState({
      isSuccess: true,
      apiError: "",
    });
  };

  onFormInput = (e) => {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  };

  onResetPassword = async () => {
    const params = new URLSearchParams(this.props.location.search);
    const code = params.get("code");
    const email = params.get("email");

    const { password, repeatPassword } = this.state;

    try {
      if (password !== repeatPassword) {
        throw new Error("The passwords don't match");
      }

      await resetPassword(email, code, password, repeatPassword);
      this.handleResetPasswordSuccess();
    } catch (error) {
      this.setState({
        apiError: error.message,
      });
    }
  };

  render() {
    const { password, repeatPassword, apiError, isSuccess } = this.state;

    return (
      <div className="content">
        <Container>
          <Col className="ml-auto mr-auto" lg="4" md="6">
            <Form className="form">
              <Card className="card-login card-white">
                <CardHeader>
                  <img alt="..." src={require("assets/img/card-primary.png")} />
                  <CardTitle tag="h6">Password</CardTitle>
                </CardHeader>
                <CardBody>
                  <InputGroup
                    className={classnames({
                      "input-group-focus": this.state.passFocus,
                    })}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="tim-icons icon-lock-circle" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Password"
                      type="password"
                      name="password"
                      onFocus={(e) => this.setState({ passFocus: true })}
                      onBlur={(e) => this.setState({ passFocus: false })}
                      value={password}
                      onChange={(e) => this.onFormInput(e)}
                    />
                  </InputGroup>
                  <InputGroup
                    className={classnames({
                      "input-group-focus": this.state.repeatPasswordFocus,
                    })}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="tim-icons icon-lock-circle" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Repeat Password"
                      type="password"
                      name="repeatPassword"
                      onFocus={(e) =>
                        this.setState({ repeatPasswordFocus: true })
                      }
                      onBlur={(e) =>
                        this.setState({ repeatPasswordFocus: false })
                      }
                      value={repeatPassword}
                      onChange={(e) => this.onFormInput(e)}
                    />
                  </InputGroup>
                </CardBody>
                <CardFooter>
                  {apiError && (
                    <UncontrolledAlert color="primary" fade={false}>
                      <span>{apiError}</span>
                    </UncontrolledAlert>
                  )}
                  {isSuccess && (
                    <UncontrolledAlert fade={false}>
                      You password was changed successfully! Please, go to the login page to
                      access your account.
                    </UncontrolledAlert>
                  )}
                  {isSuccess ? (
                    <Button
                      block
                      className="mb-3"
                      color="primary"
                      onClick={() => this.props.history.push('/login')}
                      size="lg"
                    >
                      Go to Login
                    </Button>
                  ) : (
                    <Button
                      block
                      className="mb-3"
                      color="primary"
                      onClick={() => this.onResetPassword()}
                      size="lg"
                    >
                      Confirm
                    </Button>
                  )}
                </CardFooter>
              </Card>
            </Form>
          </Col>
        </Container>
      </div>
    );
  }
}

export default ChangePassword;
