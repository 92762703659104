import React from "react";
// reactstrap components
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	CardTitle,
	Row,
	Col,
	FormGroup,
	Label,
	Input
} from "reactstrap";
// Helpers
import { getCredentials } from '../helpers/auth';
// Constants
import states from '../constants/states.json'
// Requests
import { createCompanyRequest } from "api/corporation";

class StoreCreate extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			company: null,
			error: null
		}
	}

	async componentDidMount() {
		const auth = getCredentials();
		this.setState({
			company: {
				name: '',
				role: auth.role
			}
		})
	}

	goTo = path => this.props.history.push(path);

	onChangeField = (e) => {
		const { target } = e;
		const { name, value } = target;
		this.setState(prevState => ({
			company: {
				...prevState.company,
				[name]: value,
			},
		}));
	}

	onChangeCheckbox = (e) => {
		const { target } = e;
		const { name, checked } = target;

		this.setState(prevState => ({
			company: {
				...prevState.user,
				[name]: checked,
			},
		}));
	}

	save = async () => {
		const { company } = this.state;
		const auth = getCredentials();
		const data = {
			company,
			corporationId: auth.corporationId
		}
		
		try {
			await createCompanyRequest(data);
			this.goTo('/stores');
		} catch (err) {
			console.log(err, "error");
			this.setState({
				error: err.message || 'There was an error saving store'
			})
		}
	}

	onChangeAddress = (event) => {
		const { name, value } = event.target;
		this.setState((prevState) => ({
			company: {
				...prevState.company,
				address: {
					...(prevState.company.address || {}),
					[name]: value
				}
			}
		}));
	}

	onChangeManager = (event) => {
		const { name, value } = event.target;
		this.setState((prevState) => ({
			company: {
				...prevState.company,
				manager: {
					...(prevState.company.manager || {}),
					[name]: value
				}
			}
		}));
	}

	render() {

		const {
			company = {},
			error = null
		} = this.state;

		const {
            name,
            role,
            address,
            manager
		} = company || {};

		const {
			streetAddress,
            complement,
            city,
            state,
            zipCode,
		} = address || {};

		const {
			firstName,
			lastName,
			phoneNumber,
			email,
            password
		} = manager || {};

		const isSeller = role === 'seller'

		return (
			<>
				<div className="content">
					<Row>
						<Col md="12">
							<Card>
								<CardHeader className="mb-5">
									<CardTitle tag="h3">
										Create Store
                  					</CardTitle>
									<Button
										className="float-right"
										color="default"
										onClick={() => this.goTo('/stores')}
									>
										Back
 					                </Button>
								</CardHeader>
								<CardBody>
									{!company
										? <div>Loading...</div>
										: <>
											<FormGroup>
												<Label>Name</Label>
												<Input
													type="text"
													name="name"
													value={name || ''}
													onChange={this.onChangeField}
												/>
											</FormGroup>
											<FormGroup>
												<Label>Role</Label>
												<Input
													type="select"
													name="role"
													value={role || ''}
													disabled
												>
													<option value=''>-- SELECT --</option>
													<option value="client">Client</option>
													<option value="seller">Seller</option>
												</Input>
											</FormGroup>
											{isSeller 
												&& 
                                                <Card className="detail-card mt-3">
                                                    <CardHeader>
                                                        <h3>Company Address</h3>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <FormGroup>
                                                            <Label>Company Street Address</Label>
                                                            <Input
                                                                type="text"
                                                                name="streetAddress"
                                                                value={streetAddress || ''}
                                                                onChange={this.onChangeAddress}
                                                            />
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label>Company Street Notes</Label>
                                                            <Input
                                                                type="text"
                                                                name="complement"
                                                                value={complement || ''}
                                                                onChange={this.onChangeAddress}
                                                            />
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label>Company State</Label>
                                                            <Input
                                                                type="select"
                                                                name="state"
                                                                value={state || ''}
                                                                onChange={this.onChangeAddress}
                                                            >
                                                                <option value=''>-- SELECT --</option>
                                                                {states.map(state => (
                                                                    <option value={state.value}>{state.name}</option>
                                                                ))}
                                                            </Input>
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label>Company City</Label>
                                                            <Input
                                                                type="text"
                                                                name="city"
                                                                value={city || ''}
                                                                onChange={this.onChangeAddress}
                                                            />
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label>Company Zip Code</Label>
                                                            <Input
                                                                type="text"
                                                                name="zipCode"
                                                                value={zipCode || ''}
                                                                onChange={this.onChangeAddress}
                                                            />
                                                        </FormGroup>
                                                    </CardBody>
                                                </Card>                 
											}
                                            <Card className="detail-card mt-3">
                                                <CardHeader>
                                                    <h3>Store Owner / Manager</h3>
                                                </CardHeader>
                                                <CardBody>
                                                    <FormGroup>
                                                        <Label>First Name</Label>
                                                        <Input
                                                            type="text"
                                                            name="firstName"
                                                            value={firstName || ''}
                                                            onChange={this.onChangeManager}
                                                        />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Last Name</Label>
                                                        <Input
                                                            type="text"
                                                            name="lastName"
                                                            value={lastName || ''}
                                                            onChange={this.onChangeManager}
                                                        />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Phone Number</Label>
                                                        <Input
                                                            type="text"
                                                            name="phoneNumber"
                                                            value={phoneNumber || ''}
                                                            onChange={this.onChangeManager}
                                                        />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Email</Label>
                                                        <Input
                                                            type="text"
                                                            name="email"
                                                            value={email || ''}
                                                            onChange={this.onChangeManager}
                                                        />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>One-time Password</Label>
                                                        <Input
                                                            type="text"
                                                            name="password"
                                                            value={password || ''}
                                                            onChange={this.onChangeManager}
                                                        />
                                                        <span className="form-text">
                                                            {`They will be able to change it in the app after their first Sign In.`}
                                                        </span>
                                                    </FormGroup>
                                                </CardBody>
                                            </Card>  
										</>
									}
									{error ? <div className="alert alert-danger mt-3">
											<span>{error}</span>
										</div> : null
									}
								</CardBody>
								{company ? <Col>
									<Button color="primary" block={false} className="mb-4" onClick={this.save}>
										Create Store
									</Button>
								</Col> : null}
							</Card>
						</Col>
					</Row>
				</div>
			</>
		);
	}
}

export default StoreCreate;

