import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Row,
  Col,
  Button,
  Table,
  UncontrolledTooltip
} from "reactstrap";
// Components
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
// Helpers
import { getCredentials } from 'helpers/auth';
// Requests
import { getCorporationCustomPricesRequest, deleteCorporationCustomPricesRequest } from 'api/corporation'
import productPlaceholder from 'assets/img/product-placeholder.jpg';
import { formatPrice } from "helpers/pricing";

class CustomPrices extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            corporationSettings: null,
            deleteId: ''
        }
    }

    componentDidMount() {
        const auth = getCredentials();
        if (auth.corporationId) this.getCorporationCustomPrices()
    }

    getCorporationCustomPrices = async () => {
        try {
            const auth = getCredentials();
    
            const { corporationSettings } = await getCorporationCustomPricesRequest(0, 0, '', auth.corporationId)
    
            this.setState({
                corporationSettings
            })        
        } catch (error) {
            console.log('error', error)
        }
    }


    goTo = path => this.props.history.push(path)

    openConfirmation = (deleteId) => () => {
		this.setState({
			deleteId
		});
		this.deleteConfirmationModal.toggle();
    }

    deleteCustomPrice = async () => {
		const { deleteId } = this.state;
        await deleteCorporationCustomPricesRequest(deleteId);
        this.getCorporationCustomPrices()
	}

    render() {

        const {
            corporationSettings
        } = this.state;

        const auth = getCredentials();

        return (
        <>
            <div className="content">
            <Row>
                <Col xs="12">
                    <Card className="card-chart">
                        <CardHeader className="mb-5">
                            <CardTitle tag="h3">
                                Clients with Custom Price
                            </CardTitle>
                            <Button className="pull-right mb-3" color="primary" onClick={() => this.goTo(`/custom-prices/create?corporationId=${auth.corporationId}`)}>Create New</Button>
                        </CardHeader>
                        <CardBody>
                            <Table responsive>
                                <thead className="text-primary">
                                    <tr>
                                        <th>Client</th>
                                        <th>Products Quantity</th>
                                        <th className="text-right">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {corporationSettings && corporationSettings.length
                                    ? corporationSettings.map(({
                                            _id,
                                            clientCompany,
                                            products
                                        }, index) => (
                                            <>
                                                <tr key={clientCompany?.name + index}>
                                                    <td>{clientCompany?.name}</td>
                                                    <td>{products?.length || 0} {products?.length > 1 ? 'Products' : 'Product'}</td>
                                                    <td className="text-right">
                                                        <Button
                                                            className="btn-link btn-icon"
                                                            color="success"
                                                            id={`tooltip-view-${_id}`}
                                                            size="sm"
                                                            onClick={() => this.goTo(`/custom-price?id=${_id}`)}
                                                        >
                                                            <i className="tim-icons icon-zoom-split" />
                                                        </Button>
                                                        <UncontrolledTooltip
                                                            delay={0}
                                                            target={`tooltip-view-${_id}`}
                                                        >
                                                            View
                                                        </UncontrolledTooltip>
                                                        <Button
                                                            className="btn-link btn-icon"
                                                            color="info"
                                                            id={`tooltip-edit-${_id}`}
                                                            size="sm"
                                                            onClick={() => this.goTo(`/custom-price/edit?id=${_id}`)}
                                                        >
                                                            <i className="tim-icons icon-pencil" />
                                                        </Button>
                                                        <UncontrolledTooltip
                                                            delay={0}
                                                            target={`tooltip-edit-${_id}`}
                                                        >
                                                            Edit
                                                        </UncontrolledTooltip>
                                                        <Button
                                                            className="btn-link btn-icon"
                                                            color="danger"
                                                            id={`tooltip-delete-${_id}`}
                                                            size="sm"
                                                            onClick={this.openConfirmation(_id)}
                                                        >
                                                            <i className="tim-icons icon-simple-remove" />
                                                        </Button>
                                                        <UncontrolledTooltip
                                                            delay={0}
                                                            target={`tooltip-delete-${_id}`}
                                                        >
                                                            Delete
                                                        </UncontrolledTooltip>
                                                    </td>
                                                </tr>
                                            </>
                                        ))
                                    :   <p>You don't have any Clients with Custom Prices yet</p>}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <ConfirmationModal
                ref={el => this.deleteConfirmationModal = el}
                title="Delete Entry?"
                buttons={[
                    { title: 'Cancel', className: 'btn-primary-override btn btn-primary' },
                    { title: 'Delete', className: 'btn-danger-override btn btn-danger', onClick: this.deleteCustomPrice }
                ]}
            >
                Are you sure you want to delete this entry?
            </ConfirmationModal>
            </div>
        </>
        );
    }
}

export default CustomPrices;
