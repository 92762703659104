import React from "react";

// reactstrap components
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	CardTitle,
	Row,
	Col,
	FormGroup,
	Label,
	Input
} from "reactstrap";
// Constants
import states from '../constants/states.json'
// Requests
import { getCompanyRequest, updateCompanyRequest } from '../api/company';

class StoreEdit extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			store: null
		}
	}

	async componentDidMount() {
		this.getStore()
	}

    getStore = async () => {
		const params = new URLSearchParams(this.props.location.search);
		const id = params.get('id');

		if (id) {
			const store = await getCompanyRequest(id);
			this.setState({ store })
		} else {
			this.goTo('/stores')
		}
	}

	onChangeField = (e) => {
		const { target } = e;
		const { name, value } = target;
		this.setState(prevState => ({
			store: {
				...prevState.store,
				[name]: value,
			},
		}));
	}

	onChangeAddress = (event) => {
		const { name, value } = event.target;
		this.setState((prevState) => ({
			store: {
				...prevState.store,
				address: {
					...(prevState.store.address || {}),
					[name]: value
				}
			}
		}));
	}

	save = async () => {
		const { store } = this.state;
		try {
			await updateCompanyRequest(store);
			this.goTo('/stores');
		} catch (err) {
			console.log(err, "error");
			this.setState({
				error: err.message || 'There was an error saving store'
			})
		}
	}

	goTo = path => this.props.history.push(path);

	render() {

		const {
			store,
		} = this.state;

		const {
			_id,
            name,
            role,
            owner,
            address
		} = store || {};

		const {
			streetAddress,
            complement,
            city,
            state,
            zipCode,
		} = address || {};

		const {
			email
		} = owner || {};

		const isSeller = role === 'seller'

		let firstName = "";
		let lastName = "";
		let phoneNumber = "";

		if (role) {
			firstName = owner[role].firstName;
			lastName = owner[role].lastName;
			phoneNumber = owner[role].phoneNumber;
		}

		return (
			<>
				<div className="content">
					<Row>
						<Col md="12">
							<Card>
								<CardHeader className="mb-5">
									<CardTitle tag="h3">
										Edit Store
                  					</CardTitle>
									<Button
										className="float-right"
										color="default"
										onClick={() => this.goTo('/stores')}
									>
										Back
                  					</Button>
								</CardHeader>
								<CardBody>
									{!store
										? <div>Loading...</div>
										: <>
											<FormGroup>
												<Label>Name <span className="danger-text">*</span></Label>
												<Input
													type="text"
													name="name"
													value={name || ''}
													onChange={this.onChangeField}
												/>
											</FormGroup>
											<FormGroup>
												<Label>Role</Label>
												<Input
													type="select"
													name="role"
													value={role || ''}
													disabled
												>
													<option value=''>-- SELECT --</option>
													<option value="client">Client</option>
													<option value="seller">Seller</option>
												</Input>
											</FormGroup>
											{isSeller 
												&& 
                                                <Card className="detail-card mt-3">
                                                    <CardHeader>
                                                        <h3>Company Address</h3>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <FormGroup>
                                                            <Label>Company Street Address</Label>
                                                            <Input
                                                                type="text"
                                                                name="streetAddress"
                                                                value={streetAddress || ''}
                                                                onChange={this.onChangeAddress}
                                                            />
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label>Company Street Notes</Label>
                                                            <Input
                                                                type="text"
                                                                name="complement"
                                                                value={complement || ''}
                                                                onChange={this.onChangeAddress}
                                                            />
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label>Company State</Label>
                                                            <Input
                                                                type="select"
                                                                name="state"
                                                                value={state || ''}
                                                                onChange={this.onChangeAddress}
                                                            >
                                                                <option value=''>-- SELECT --</option>
                                                                {states.map(state => (
                                                                    <option value={state.value}>{state.name}</option>
                                                                ))}
                                                            </Input>
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label>Company City</Label>
                                                            <Input
                                                                type="text"
                                                                name="city"
                                                                value={city || ''}
                                                                onChange={this.onChangeAddress}
                                                            />
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label>Company Zip Code</Label>
                                                            <Input
                                                                type="text"
                                                                name="zipCode"
                                                                value={zipCode || ''}
                                                                onChange={this.onChangeAddress}
                                                            />
                                                        </FormGroup>
                                                    </CardBody>
                                                </Card>                 
											}
                                            <Card className="detail-card mt-3">
                                                <CardHeader>
                                                    <h3>Store Owner / Manager</h3>
                                                </CardHeader>
                                                <CardBody>
                                                    <FormGroup>
                                                        <Label>First Name</Label>
                                                        <Input
                                                            type="text"
                                                            name="firstName"
                                                            value={firstName || ''}
                                                            disabled
                                                        />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Last Name</Label>
                                                        <Input
                                                            type="text"
                                                            name="lastName"
                                                            value={lastName || ''}
                                                            disabled
                                                        />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Phone Number</Label>
                                                        <Input
                                                            type="text"
                                                            name="phoneNumber"
                                                            value={phoneNumber || ''}
                                                            disabled
                                                        />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Email</Label>
                                                        <Input
                                                            type="text"
                                                            name="email"
                                                            value={email || ''}
                                                            disabled
                                                        />
                                                    </FormGroup>
                                                </CardBody>
                                            </Card>
										</>
									}
								</CardBody>
								{store ? <Col>
									<Button color="primary" block={false} className="mb-4" onClick={this.save}>
										Save Changes
									</Button>
								</Col> : null}
							</Card>
						</Col>
					</Row>
				</div>
			</>
		);
	}
}

export default StoreEdit;
