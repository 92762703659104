import { apiRequest} from './index'

export const createTransferRequest = (data) => {
    return apiRequest(
      `/corporations/transfers`,
      {
              method: 'POST',
              body: JSON.stringify(data)
      },
      true
    )
}

export const getTransfersRequest = (page, limit, search, status, corporation, originCompany, destinationCompany) => {
  return apiRequest(
    `/corporations/transfers?page=${page}&limit=${limit}&search=${search}&status=${status}&corporation=${corporation}&originCompany=${originCompany}&destinationCompany=${destinationCompany}`,
    {
      method: 'GET'
    },
    true
  )
}

export const getTransferByIdRequest = (id) => {
  return apiRequest(
    `/corporations/transfers/${id}`,
    {
      method: 'GET'
    },
    true
  )
}

export const deleteTransferRequest = (id) => {
    return apiRequest(
      `/corporations/transfers/${id}`,
      {
        method: 'DELETE'
      },
      true
    )
  }