import { apiRequest} from './index'

export const createCorporationSettingRequest = (data) => {
    return apiRequest(
      `/corporations/settings`,
      {
              method: 'POST',
              body: JSON.stringify(data)
      },
      true
    )
}

export const getCorporationSettingsRequest = (page, limit, search, corporation, type) => {
    return apiRequest(
      `/corporations/settings?page=${page}&limit=${limit}&search=${search}&corporation=${corporation}&type=${type}`,
      {
        method: 'GET'
      },
      true
    )
}
  
export const getCorporationSettingRequest = (id) => {
    return apiRequest(
      `/corporations/settings/${id}`,
      {
        method: 'GET'
      },
      true
    )
}

export const editCorporationSettingRequest = (data) => {
  return apiRequest(
    `/corporations/settings/${data._id}`,
    {
			method: 'PUT',
			body: JSON.stringify(data)
    },
    true
  )
}


export const deleteCorporationSettingsRequest = (id) => {
  return apiRequest(
    `/corporations/settings/${id}`,
    {
			method: 'DELETE'
    },
    true
  )
}

export const deleteProductFromCustomPriceRequest = (customPriceId, productId) => {
  return apiRequest(
    `/corporations/custom-prices/${customPriceId}/product/${productId}`,
    {
			method: 'DELETE'
    },
    true
  )
}

export const updateClientTiersInBulkRequest = (data) => {
  return apiRequest(
    `/corporations/settings/update-tiers/${data._id}`,
    {
            method: 'POST',
            body: JSON.stringify(data)
    },
    true
  )
}
export const saveProductIntoCustomPriceRequest = (customPriceId, data) => {
  return apiRequest(
    `/corporations/custom-prices/${customPriceId}/save-product`,
    {
            method: 'POST',
            body: JSON.stringify(data)
    },
    true
  )
}
export const editCustomPriceProductRequest = (customPriceId, data) => {
  return apiRequest(
    `/corporations/custom-prices/${customPriceId}/edit`,
    {
            method: 'PUT',
            body: JSON.stringify(data)
    },
    true
  )
}

export const getClientTierByClientCompanyIdAndCorporationRequest = (companyId, corporationId) => {
  return apiRequest(
    `/corporations/settings/client-tier/${corporationId}?clientCompany=${companyId}`,
    {
      method: 'GET'
    },
    true
  )
}