import React, { PureComponent } from 'react';
import { Container, Row, Col, FormGroup, Label, Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert, Input, Card, CardHeader } from 'reactstrap';
import { getProductsRequest } from 'api/product';
import Async from 'react-select/async';
import productPlaceholder from 'assets/img/product-placeholder.jpg';


export default class CustomPriceModal extends PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			formCustomPriceProduct: null,
		}
	}

	componentDidUpdate(prevProps) {
        if(this.props.customPriceProduct !== prevProps.customPriceProduct) {
            this.setState({
                formCustomPriceProduct: this.props.customPriceProduct
            })
        }

    }

	componentWillUnmount() {
		this.setState({
			formCustomPriceProduct: null
		})
    }

	onSave = () => {
		const { 
			onSave,
		} = this.props;
		const { 
			formCustomPriceProduct,
			selectedProduct
		} = this.state;

		

		onSave({
			...formCustomPriceProduct,
			product: selectedProduct ? selectedProduct.data :  formCustomPriceProduct.product
		})
	}

	onSelectChange = (key) => (value) => {
		this.setState({
			[key]: value
		});
	}

    loadProducts = async (text, callback) => {
		if(text) {
			const productsResponse = await getProductsRequest(0, 0, text, '', '');
			const products = productsResponse.products.map(product => {
				return {
					data: product,
					label: 
					<div>
						<img 
							className="mr-3" 
							height="30px" 
							width="30px" 
                            alt={product?.name}
							src={product.images[0] ? product.images[0] : productPlaceholder}>
						</img>
							{product.name}
					</div>,
					value: product._id
				}
			});
			callback(products);
		}
	}

	onChangeField = (e) => {
		const { target } = e;
		const { name, value } = target;
		
		this.setState({
			formCustomPriceProduct: {
				...this.state.formCustomPriceProduct,
				[name]: value
			},
		});
	}

	render() {

		const { 
			formCustomPriceProduct,
			selectedProduct
		} = this.state;

		const {
			isLoading,
			isOpen,
			onClose,
			isCreateNewCustomPrice,
			error
		} = this.props;


		return (
			<Modal isOpen={isOpen} toggle={onClose}>
				<ModalHeader>{isCreateNewCustomPrice ? 'Create' : 'Edit'} Custom Product Price</ModalHeader>
				<ModalBody>
					<Container>
						{
							isCreateNewCustomPrice ?
							<Row>
								<Col>	
									<FormGroup>
										<Label>Product</Label>
										<Async
											value={selectedProduct}
											name="selectedProduct"
											options={[]}
											loadOptions={this.loadProducts}
											className="basic-multi-select light-async-select"
											classNamePrefix="select"
											onChange={this.onSelectChange('selectedProduct')}
											isClearable
											placeholder="Start typing to load products..."
										/>
									</FormGroup>
								</Col>
							</Row>
							:
							<>
								
								<Label>Product</Label>
								<div className="d-flex align-items-center p-2 mb-2" style={{borderRadius: '0.5rem', borderColor: '#2b3553', border: 'solid 1px'}}>
									<div style={{width: '80px'}}>
										<img 
											className="mr-3" 
											height="80px" 
											width="80px" 
											alt={formCustomPriceProduct?.product?.name}
											src={formCustomPriceProduct?.product?.images[0] ? formCustomPriceProduct?.product?.images[0] : productPlaceholder}>
										</img>
									</div>
									<div className='p-3'>
										<h4 style={{color: '#525f7f', margin: 0}}>{formCustomPriceProduct?.product?.name}</h4>

									</div>

								</div>
							
							</>
						}
						<Row>
							<Col>	
								<FormGroup>
									<Label>Custom Price</Label>
									<Input
										type="text"
										name="price"
										value={formCustomPriceProduct?.price || ''}
										onChange={this.onChangeField}
										style={{
											backgroundColor: 'white',
											color: '#525f7f'
										}}
									/>
								</FormGroup>
							</Col>
						</Row>
					</Container>
					{error && 
						<Alert color="danger">
							{error}
						</Alert>
					}
				</ModalBody>
				<ModalFooter className="justify-content-end">
					<Button 
						color="primary" 
						className="btn-success-override mr-2"
						disabled={!formCustomPriceProduct || isLoading}
						onClick={this.onSave}
					>
						{isLoading ? "Saving..." : "Save"}
					</Button>
					<Button color="secondary" className="btn-danger-override" onClick={onClose}>Cancel</Button>
				</ModalFooter>
			</Modal>
		)
	}
}