import { getDistance } from 'geolib';

const metersToMiles = (meters=0) => {
    if (!meters) return 0;
	if (typeof meters === 'string') {
		meters = Number(meters);
	}
	let formattedValue = (meters/1600).toFixed(1);

	return formattedValue;
}

export const calculateDeliveryFee = (pickupAddress, deliveryAddress) => {
    // Get PickUp and Delivery addresses
    // Calculate distance between them
    const distance = metersToMiles(getDistance(
        { latitude: pickupAddress.latitude, longitude: pickupAddress.longitude },
        { latitude: deliveryAddress.latitude, longitude: deliveryAddress.longitude }
    ));
    // Calculate price based on distance
    let baseFare = 3.99;
    let mileageFare;
    if (distance < 40) {
        mileageFare = distance * 2.0
    } else {
        mileageFare = distance * 1.5
    }

    return mileageFare + baseFare;
}